import React, { Suspense, lazy } from 'react'
import { Route, Redirect } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import { CSSTransition, SwitchTransition } from 'react-transition-group'
import Switch from 'react-router-transition-switch'
import { connect } from 'react-redux'
import { filterRoutesByDisabledURLs, filterRoutesByPermissions } from 'services/utilities'
import Layout from 'layouts'
import Loader from 'components/layout/Loader'
import NotFoundPage from 'pages/system/404'
import { PermissionsTable as pt } from 'services/permissions'
import ManagerTourer from 'components/ManagerTourer'
import { ConfigProvider } from 'antd'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import customToken, { components } from './customToken'

const routeList = [
  // Legal Documents
  {
    path: '/system/switch-user/:email',
    disabledUrls: [],
    Component: lazy(() => import('pages/system/switch-user')),
    exact: true,
  },
  // Legal Documents
  {
    path: '/agreements/:type_name',
    disabledUrls: [],
    Component: lazy(() => import('pages/agreements')),
    exact: true,
  },
  // Public Pages
  {
    path: '/pricing',
    disabledUrls: ['kronnika.io'],
    Component: lazy(() => import('pages/public-pages/pricing')),
    exact: true,
  },
  // Billing
  {
    path: '/my-packages',
    disabledUrls: [],
    Component: lazy(() => import('pages/billing/my-packages')),
    exact: true,
    auth_users: pt.billing.all,
  },
  {
    path: '/buy-new-package',
    disabledUrls: [],
    Component: lazy(() => import('pages/billing/my-packages/newPackagePage')),
    exact: true,
    auth_users: pt.billing.all,
  },
  {
    path: '/payment-methods',
    disabledUrls: [],
    Component: lazy(() => import('pages/billing/payment-methods')),
    exact: true,
    auth_users: pt.billing.all,
  },
  {
    path: '/invoices',
    disabledUrls: [],
    Component: lazy(() => import('pages/billing/invoices/')),
    exact: true,
    auth_users: pt.billing.all,
  },
  {
    path: '/invoices/:id',
    disabledUrls: [],
    Component: lazy(() => import('pages/billing/invoices/detail')),
    exact: true,
    auth_users: pt.billing.all,
  },
  // Payouts
  {
    path: '/payouts/add-wise-account',
    disabledUrls: [],
    Component: lazy(() => import('pages/payouts/payout-account')),
    exact: true,
    auth_users: pt.payouts.default,
  },
  {
    path: '/payouts/add-bank-account',
    disabledUrls: [],
    Component: lazy(() => import('pages/payouts/payout-account')),
    exact: true,
    auth_users: pt.payouts.default,
  },
  {
    path: '/payouts',
    disabledUrls: [],
    Component: lazy(() => import('pages/payouts')),
    exact: true,
    auth_users: pt.payouts.default,
  },

  // Info & Support
  {
    path: '/documentation',
    disabledUrls: [],
    Component: lazy(() => import('pages/info-support/knowledge-base')),
    exact: true,
    auth_users: pt.info_support.all,
  },
  {
    path: '/info-support',
    disabledUrls: [],
    Component: lazy(() => import('pages/info-support')),
    exact: true,
    auth_users: pt.info_support.all,
  },
  {
    path: '/info-support/faq',
    disabledUrls: [],
    Component: lazy(() => import('pages/info-support/faq')),
    exact: true,
    auth_users: pt.info_support.all,
  },
  {
    path: '/info-support/release-notes',
    disabledUrls: [],
    Component: lazy(() => import('pages/info-support/release-notes')),
    exact: true,
    auth_users: pt.info_support.all,
  },
  {
    path: '/info-support/agreements/:type_name',
    disabledUrls: [],
    Component: lazy(() => import('pages/info-support/legal-documents')),
    exact: true,
    auth_users: pt.info_support.all,
  },
  {
    path: '/info-support/legal-documents',
    disabledUrls: [],
    Component: lazy(() => import('pages/info-support/legal-documents')),
    exact: true,
    auth_users: pt.info_support.all,
  },

  {
    path: '/info-support/library-documentation',
    disabledUrls: [],
    Component: lazy(() => import('pages/info-support/library-documentation')),
    exact: true,
    auth_users: pt.info_support.all,
  },
  {
    path: '/info-support/library-documentation/:category/:item',
    disabledUrls: [],
    Component: lazy(() => import('pages/info-support/library-documentation')),
    exact: true,
    auth_users: pt.info_support.all,
  },
  {
    path: '/info-support/knowledge-base/new-topic',
    disabledUrls: [],
    Component: lazy(() => import('pages/info-support/knowledge-base/newTopic')),
    exact: true,
    auth_users: pt.info_support.modify,
  },
  {
    path: '/info-support/knowledge-base/:document_key',
    disabledUrls: [],
    Component: lazy(() => import('pages/info-support/knowledge-base')),
    exact: true,
    auth_users: pt.info_support.all,
  },
  {
    path: '/info-support/knowledge-base',
    disabledUrls: [],
    Component: lazy(() => import('pages/info-support/knowledge-base')),
    exact: false,
    auth_users: pt.info_support.all,
  },

  {
    path: '/info-support/support-center',
    disabledUrls: [],
    Component: lazy(() => import('pages/info-support/support-center')),
    exact: true,
    auth_users: pt.info_support.all,
  },
  // Subscription
  {
    path: '/subscription-plans',
    disabledUrls: [],
    Component: lazy(() => import('pages/billing/pricing')),
    exact: true,
  },
  // Kronnika News
  {
    path: '/kronnika-news',
    Component: lazy(() => import('pages/kronnika-news')),
    exact: true,
  },
  // Reports & Statistics
  {
    path: '/reports/?type=:type&from_date=:from&to_date=:to',
    Component: lazy(() => import('pages/reports')),
    exact: true,
    auth_users: pt.reports.all,
  },
  {
    path: '/reports',
    Component: lazy(() => import('pages/reports')),
    exact: true,
  },

  // Organization Management
  {
    path: '/organization-management/manage-organization/:id',
    Component: lazy(() => import('pages/organization-management/edit-organization')),
    exact: true,
    auth_users: pt.organization_management.manage_organizations,
  },
  {
    path: '/organization-management/manage-organizations',
    Component: lazy(() => import('pages/organization-management/manage-organizations')),
    exact: true,
    auth_users: pt.organization_management.manage_organizations,
  },

  {
    path: '/organization-management/my-organization',
    Component: lazy(() => import('pages/organization-management/my-organization')),
    exact: true,
    auth_users: pt.organization_management.my_organization,
  },
  {
    path: '/organization-management/new-organization',
    Component: lazy(() => import('pages/organization-management/new-organization')),
    exact: true,
    auth_users: pt.organization_management.manage_organizations,
  },
  {
    path: '/organization-management/manage-users',
    Component: lazy(() => import('pages/organization-management/manage-users')),
    exact: true,
    auth_users: pt.organization_management.manage_users,
  },
  {
    path: '/organization-management/manage-teams',
    Component: lazy(() => import('pages/organization-management/manage-teams')),
    exact: true,
    auth_users: pt.organization_management.manage_teams,
  },
  // User Profile Page
  {
    path: '/user/profile',
    Component: lazy(() => import('pages/user/profile')),
    exact: true,
  },
  // Robot Manager
  {
    path: '/robot-manager/robots',
    Component: lazy(() => import('pages/robot-manager/robots')),
    exact: true,
    auth_users: pt.robot_manager.robot_list,
  },
  {
    path: '/robot-manager/new-robot',
    Component: lazy(() => import('pages/robot-manager/new-robot')),
    exact: true,
    auth_users: pt.robot_manager.create_new_robot,
  },
  {
    path: '/robot-manager/robots/:id',
    Component: lazy(() => import('pages/robot-manager/robots/profile')),
    exact: true,
    auth_users: pt.robot_manager.robot_list,
  },
  {
    path: '/jobs-schedules',
    Component: lazy(() => import('pages/jobs-schedules')),
    exact: true,
    auth_users: pt.jobs_schedules.all,
  },
  // Queues
  {
    path: '/queues/new-queue',
    Component: lazy(() => import('pages/queues/new-queue')),
    exact: true,
    auth_users: pt.queues.all,
  },
  {
    path: '/queues/:id/queue-items',
    Component: lazy(() => import('pages/queues/queue-items')),
    exact: true,
    auth_users: pt.queues.all,
  },
  {
    path: '/queues/:id',
    Component: lazy(() => import('pages/queues/edit-queue')),
    exact: true,
    auth_users: pt.queues.all,
  },
  {
    path: '/queues',
    Component: lazy(() => import('pages/queues')),
    exact: true,
    auth_users: pt.queues.all,
  },
  // Vaults
  {
    path: '/vaults',
    Component: lazy(() => import('pages/vaults')),
    exact: true,
    auth_users: pt.all_users,
  },

  // Scenario Designer
  {
    path: '/scenario-designer',
    Component: lazy(() => import('pages/scenario-designer')),
    exact: true,
    auth_users: pt.scenario_designer.all,
  },
  {
    path: '/scenario-designer/',
    Component: lazy(() => import('pages/scenario-designer')),
    exact: true,
    auth_users: pt.scenario_designer.all,
  },
  {
    path: '/scenario-designer/:id',
    Component: lazy(() => import('pages/scenario-designer')),
    exact: true,
    auth_users: pt.scenario_designer.all,
  },
  {
    path: '/scenario-designer/new-scenario',
    Component: lazy(() => import('pages/scenario-designer')),
    exact: true,
    auth_users: pt.scenario_designer.all,
  },

  // Dashboards
  {
    path: '/dashboard',
    Component: lazy(() => import('pages/dashboard')),
    exact: true,
  },

  // Catalogs
  {
    path: '/catalogs',
    disabledUrls: [],
    Component: lazy(() => import('pages/catalogs')),
    exact: true,
    auth_users: pt.company_catalog.view_use,
  },
  {
    path: '/catalogs/:id',
    disabledUrls: [],
    Component: lazy(() => import('pages/catalogs/detail')),
    exact: true,
    auth_users: pt.company_catalog.view_use,
  },

  /** PUBLIC MARKETPLACE */
  {
    path: '/marketplace/explore',
    // disabledUrls: ['kronnika.io'],
    Component: lazy(() => import('pages/public-pages/marketplace')),
    exact: true,
  },
  {
    path: '/marketplace/explore/:id',
    // disabledUrls: ['kronnika.io'],
    Component: lazy(() => import('pages/public-pages/marketplace/detail')),
    exact: true,
  },
  {
    path: '/marketplace',
    // disabledUrls: ['kronnika.io'],
    Component: lazy(() => import('pages/public-pages/landing')),
    exact: true,
  },

  {
    path: '/market',
    disabledUrls: [],
    Component: lazy(() => import('pages/marketplace')),
    exact: true,
    auth_users: pt.robot_marketplace.view_use,
  },
  {
    path: '/market/pending-publish-requests',
    disabledUrls: [],
    Component: lazy(() => import('pages/catalogs')),
    exact: true,
    auth_users: pt.robot_marketplace.pendings,
  },
  {
    path: '/market/:id',
    disabledUrls: [],
    Component: lazy(() => import('pages/marketplace/detail')),
    exact: true,
    auth_users: pt.robot_marketplace.view_use,
  },

  // System Pages
  {
    path: '/system/login',
    Component: lazy(() => import('pages/system/login')),
    exact: true,
  },

  {
    path: '/register',
    disabledUrls: [],
    Component: lazy(() => import('pages/system/register')),
    exact: true,
  },

  {
    path: '/notifications',
    disabledUrls: [],
    Component: lazy(() => import('pages/notifications')),
    exact: true,
  },

  {
    path: '/redirect/analytics',
    Component: lazy(() => import('pages/redirect/analytics')),
    exact: true,
  },

  {
    path: '/system/verify-registration',
    Component: lazy(() => import('pages/system/register-verify')),
    exact: true,
  },
  /*   {
    path: '/system/forgot-password',
    Component: lazy(() => import('pages/system/forgot-password')),
    exact: true,
  },
  {
    path: '/system/reset-password',
    Component: lazy(() => import('pages/system/reset-password')),
    exact: true,
  }, */
  {
    path: '/system/404',
    Component: lazy(() => import('pages/system/404')),
    exact: true,
  },
  {
    path: '/system/500',
    Component: lazy(() => import('pages/system/500')),
    exact: true,
  },
]

const filterRoutes = (routeList, user) => {
  const permList = routeList.filter(r => filterRoutesByPermissions(r, user))
  const finalList = permList.filter(r => filterRoutesByDisabledURLs(r))
  return finalList
}
const mapStateToProps = ({ settings, user }) => ({ settings, user })

if (window.location.href.includes(`${window.location.origin}/#/`)) {
  window.location = window.location.href.replace('/#/', '/')
}

const Router = ({ history, settings: { routerAnimation }, user }) => {
  const routes = filterRoutes(routeList, user)
  const primary = {
    main: customToken.colorPrimary,
    light: customToken.colorPrimary,
    dark: customToken.colorPrimary,
    contrastText: '#fff',
  }

  const theme = createTheme({
    palette: {
      primary,
    },
    typography: {
      fontFamily: [
        'DM Sans',
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
    },
  })
  return (
    <ConnectedRouter history={history}>
      <ConfigProvider
        theme={{
          token: { ...customToken },
          components: {
            Breadcrumb: components.Breadcrumb,
          },
        }}
      >
        <ThemeProvider theme={theme}>
          <ManagerTourer>
            <Layout>
              <Suspense fallback={<Loader />}>
                <Switch
                  render={props => {
                    const {
                      children,
                      location: { pathname },
                    } = props
                    return (
                      <SwitchTransition>
                        <CSSTransition
                          key={pathname}
                          classNames={routerAnimation}
                          timeout={routerAnimation === 'none' ? 0 : 300}
                        >
                          {children}
                        </CSSTransition>
                      </SwitchTransition>
                    )
                  }}
                >
                  <Route exact path="/" render={() => <Redirect to="/dashboard" />} />
                  {routes.map(({ path, Component, exact }) => (
                    <Route path={path} key={path} exact={exact}>
                      <Component />
                    </Route>
                  ))}
                  <Route component={NotFoundPage} />
                </Switch>
              </Suspense>
            </Layout>
          </ManagerTourer>
        </ThemeProvider>
      </ConfigProvider>
    </ConnectedRouter>
  )
}

export default connect(mapStateToProps)(Router)
