import Icon from '@ant-design/icons'
import React from 'react'

const color = '#786fa4'
export const VaultSVG = ({ width = 1 }) => (
  <svg
    id="vault"
    height="1em"
    viewBox="0 0 24 24"
    width={`${width}em`}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill={color}
      d="m22.25 24h-5.5c-.965 0-1.75-.785-1.75-1.75v-3.5c0-.965.785-1.75 1.75-1.75h5.5c.965 0 1.75.785 1.75 1.75v3.5c0 .965-.785 1.75-1.75 1.75zm-5.5-5.5c-.138 0-.25.112-.25.25v3.5c0 .138.112.25.25.25h5.5c.138 0 .25-.112.25-.25v-3.5c0-.138-.112-.25-.25-.25z"
    />
    <path
      fill={color}
      d="m21.25 18.5h-3.5c-.414 0-.75-.336-.75-.75v-2.25c0-1.378 1.121-2.5 2.5-2.5s2.5 1.122 2.5 2.5v2.25c0 .414-.336.75-.75.75zm-2.75-1.5h2v-1.5c0-.551-.448-1-1-1s-1 .449-1 1z"
    />
    <path
      fill={color}
      d="m8.5 21c-.048 0-.096-.004-.143-.014-.084-.016-8.357-1.725-8.357-11.056v-6.18c0-.31.19-.587.479-.699l7.75-3c.174-.067.367-.067.541 0l7.75 3c.29.112.48.389.48.699v6.18c0 .569-.031 1.103-.096 1.63-.05.411-.416.699-.835.654-.411-.05-.704-.424-.653-.835.057-.467.084-.941.084-1.45v-5.665l-7-2.709-7 2.709v5.666c0 7.555 5.948 9.302 6.995 9.551.445-.111 1.885-.533 3.327-1.604.335-.248.803-.178 1.05.155.246.333.177.803-.155 1.049-2.014 1.495-3.992 1.889-4.075 1.904-.047.011-.094.015-.142.015z"
    />
  </svg>
)

const VaultIcon = props => <Icon component={VaultSVG} {...props} />
export default VaultIcon
