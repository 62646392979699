/* eslint-disable import/no-named-as-default */
/* eslint-disable import/no-mutable-exports */
/* eslint-disable import/no-cycle */
/* eslint-disable no-nested-ternary */
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { FlowChart, actions } from 'custom-libraries/react-flow-chart'
import { findSelectedPair, deletePairNode } from 'utils/scenario_designer_utils'
import { getIntl } from 'localization'
import { App } from 'antd'
import { setModified } from 'utils/scenarioChangeHandler'

import { getDebugEnabledNodesForScenario, newNode } from './helper'
import { CanvasOuterCustom } from './styledComponents'
import NodeCustom from './CustomNodes/NodeCustom'
import Dummy from './CustomNodes/Dummy'
import PortCustom from './CustomNodes/PortCustom'
import LinkCustom from './CustomNodes/LinkCustom'
import CanvasInner from './CustomNodes/CanvasInner'
import attachShortCutListeners, { detachListeners } from './shortcutListeners'
import { getEmptyTaskChart } from 'services/active_task'
import URLParamChanger from 'utils/urlParamChanger'

// eslint-disable-next-line import/no-mutable-exports
export let taskStateActions

const getType = (chart, id) => {
  const node = chart.nodes[id]
  return node.type
}

const mapStateToProps = ({ active_scenario, scenario_editor, settings, router }) => ({
  active_scenario,
  scenario_editor,
  settings,
  router,
})
const TaskEditor = ({
  scenario_editor: { clipboard, nodeAddMode },
  active_scenario,
  router,
  dispatch,
}) => {
  const { taskId } = router.location.query
  if (!active_scenario.content.chart.nodes[taskId]) {
    URLParamChanger({ taskId: undefined })
  }
  const taskChart =
    active_scenario.id &&
    active_scenario.content.chart.nodes &&
    active_scenario.content.chart.nodes[taskId] &&
    active_scenario.content.chart.nodes[taskId].properties?.chart.nodes
      ? active_scenario.content.chart.nodes[taskId].properties.chart
      : getEmptyTaskChart()

  // Old scenarios temporary picked attribute fix
  if (!taskChart.picked?.nodes) {
    Object.assign((taskChart.picked = { nodes: [], links: [], ports: [] }))
  }
  if (!taskChart.picked?.nodes) {
    Object.assign((taskChart.picked = { nodes: [], links: [], ports: [] }))
  }
  // *
  const setTaskChart = chart => {
    dispatch({
      type: 'active_scenario/SET_TASK_CHART',
      payload: { chart, taskId },
    })
  }

  const { modal } = App.useApp()

  const confirmDeletion = async () => {
    return new Promise(resolve => {
      modal.confirm({
        content: getIntl('confirmation.general.delete'),
        onOk: () => resolve(true),
        onCancel: () => resolve(false),
        okText: getIntl('Delete'),
      })
    })
  }
  // taskStateActions = mapValues(actions, func => (...args) => setTaskChart(func(...args), '-'))

  taskStateActions = Object.keys(actions).reduce((obj, key) => {
    obj[key] = async (...args) => {
      const action = actions[key]
      if (key === 'onDeleteKey') {
        const confirmation = await confirmDeletion()
        if (!confirmation) return taskChart
        setModified('scenario')
        const pairs = findSelectedPair(taskChart)
        if (pairs && pairs.length > 0) {
          deletePairNode(taskChart, pairs)
        }
        dispatch({
          type: 'active_step/RESET',
        })
      }
      const newChartTransformer = action(...args)
      const newChart = newChartTransformer(taskChart)
      const lastChart = { ...taskChart, ...newChart }
      setTaskChart(lastChart)

      if (key === 'onNodeClick') {
        if (
          lastChart.picked &&
          lastChart.picked.nodes.length === 1 &&
          getType(lastChart, lastChart.picked.nodes[0]) === 'node'
        ) {
          dispatch({
            type: 'active_step/SET_ACTIVE_STEP',
            payload: { stepId: lastChart.picked.nodes[0], taskId },
          })
          dispatch({
            type: 'scenario_editor/SET_STATE',
            payload: { librarySidebarOpen: true, highlightedNode: null },
          })
        }
      }

      if (key === 'onCanvasClick') {
        handleCanvasClick(args[0])
        dispatch({
          type: 'active_step/RESET',
        })
      }

      if (key === 'onContextMenu') {
        handleCanvasRightClick(args[0])
      }

      if (key === 'onCanvasDrop') {
        dispatch({
          type: 'active_step/RESET',
        })
        setModified('scenario') // On library item drop, raise a flag for change
        // taskStateActions.onNodeClick()
        if (
          lastChart.picked &&
          lastChart.picked.nodes.length === 1 &&
          getType(lastChart, lastChart.picked.nodes[0]) === 'node'
        ) {
          dispatch({
            type: 'active_step/SET_ACTIVE_STEP',
            payload: { stepId: lastChart.picked.nodes[0], taskId },
          })
          dispatch({
            type: 'scenario_editor/SET_STATE',
            payload: { librarySidebarOpen: true },
          })
        }
      }

      return newChart
    }
    return obj
  }, {})

  const handleCanvasRightClick = ({ position }) => {
    if (clipboard.data && clipboard.mode === 'task') {
      dispatch({
        type: 'scenario_editor/CLIPBOARD_PASTE',
        modal,
        payload: { position },
      })
      return true
    }
  }
  const handleCanvasClick = ({ position }) => {
    if (nodeAddMode) {
      switch (nodeAddMode) {
        case 'if_step':
          newNode({ type: 'if_step', position }, taskStateActions.onCanvasDrop)
          break
        case 'while_step':
          newNode({ type: 'while_step', position }, taskStateActions.onCanvasDrop)
          break
        case 'tryCatch_step':
          newNode({ type: 'tryCatch_step', position }, taskStateActions.onCanvasDrop)
          break
        case 'forEach_step':
          newNode({ type: 'forEach_step', position }, taskStateActions.onCanvasDrop)
          break
        default:
          break
      }
    }
    dispatch({
      type: 'scenario_editor/SET_STATE',
      payload: { nodeAddMode: false, highlightedNode: null },
    })
  }

  useEffect(() => {
    if (taskChart) {
      attachShortCutListeners('task', taskStateActions)
    }
  }, [taskId])

  useEffect(() => {
    return () => {
      detachListeners()
    }
  }, [])
  useEffect(() => {
    const debugEnabledNodes = getDebugEnabledNodesForScenario(active_scenario)
    dispatch({
      type: 'scenario_editor/SET_STATE',
      payload: { debugEnabledNodes },
    })
  }, [active_scenario])
  return (
    active_scenario.id && (
      <div
        style={{
          overflow: 'scroll',
          height: 'calc(100vh - 100px)',
          scrollbarWidth: 'none',
        }}
      >
        <FlowChart
          chart={taskChart}
          config={{
            nodeAddMode,
          }}
          callbacks={taskStateActions}
          isTask
          Components={{
            Node: NodeCustom,
            NodeInner: Dummy,
            Port: PortCustom,
            CanvasOuter: CanvasOuterCustom,
            CanvasInner,
            Link: LinkCustom,
          }}
        />
      </div>
    )
  )
}

export default connect(mapStateToProps)(TaskEditor)
