import React, { useState, useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import FileBrowser, { Icons } from 'custom-libraries/react-keyed-file-browser'

import './style.css'
import { MEDIA_URL } from 'index'

const mapStateToProps = ({ settings, files, user }) => ({ settings, files, user })

const Files = ({
  workspace,
  id,
  publicFiles,
  files: { files, loading },
  user,
  settings,
  dispatch,
}) => {
  const [fileList, setFileList] = useState(null)
  const [tempDir, setTempDir] = useState(null)

  const inputFileRef = useRef(null)

  const sendFiles = fileList => {
    if (fileList && Object.keys(fileList).length && Object.keys(fileList).length > 0) {
      Object.keys(fileList).forEach(key => {
        const file = fileList[key]
        dispatch({
          type: 'files/UPLOAD_FILE',
          payload: {
            workspace,
            id,
            file,
            path: tempDir || '',
            publicFiles,
          },
        })
      })
    }

    setFileList(null)
    setTempDir(null)
  }

  useEffect(() => {
    sendFiles(fileList)
  }, [fileList])

  const uploadFile = e => {
    const fileList = e.target.files
    setFileList(fileList)
  }

  const handleCreateDirectory = path => {
    dispatch({
      type: 'files/CREATE_DIRECTORY',
      payload: {
        workspace,
        id,
        path,
        publicFiles,
      },
    })
  }

  const handleCreateFile = (fileList, path) => {
    if (!fileList) {
      if (path) setTempDir(path)
      inputFileRef.current.click()
      return true
    }

    Object.keys(fileList).forEach(key => {
      const file = fileList[key]
      dispatch({
        type: 'files/UPLOAD_FILE',
        payload: {
          workspace,
          id,
          file,
          path,
          publicFiles,
        },
      })
    })
  }

  const handleDeleteFile = files => {
    dispatch({
      type: 'files/DELETE_FILE',
      payload: {
        publicFiles,
        workspace,
        id,
        files,
      },
    })
  }

  const handleDeleteFolder = path => {
    const innerFiles = files
      .filter(file => file.key.startsWith(path) && file.type === 'file')
      .map(file => file.key)
    const innerFolders = files
      .filter(file => file.key.startsWith(path) && file.type !== 'file')
      .map(file => file.key)

    const willBeDeletedFiles = [...innerFiles, `${path}.dirKeep`]
    innerFolders.forEach(folder => {
      willBeDeletedFiles.push(`${folder}.dirKeep`)
    })

    const ordered = willBeDeletedFiles.sort((a, b) => b.length - a.length)

    dispatch({
      type: 'files/DELETE_FILE',
      payload: {
        workspace,
        id,
        files: ordered,
        publicFiles,
      },
    })
  }

  const handleDownloadFile = file => {
    dispatch({
      type: 'files/DOWNLOAD_FILE',
      payload: {
        workspace,
        id,
        file,
        publicFiles,
      },
    })
  }

  useEffect(() => {
    dispatch({
      type: 'files/GET_LIST',
      payload: {
        publicFiles,
        workspace,
        id,
        recursive: true,
      },
    })
    return () => dispatch({ type: 'files/SET_STATE', payload: { loading: false, files: [] } })
  }, [id])

  const handlePublicLinkCopy = file => {
    const publicLinkURL = `${MEDIA_URL}/organisations/${user?.organizations[0].id}/${workspace}/${id}/_public/${file}`
    navigator.clipboard.writeText(publicLinkURL).then(
      () => {},
      () => {},
    )
  }
  return (
    <div>
      <FileBrowser
        locale={settings.locale}
        files={files}
        icons={Icons.FontAwesome(4)}
        onCreateFolder={handleCreateDirectory}
        onCreateFiles={handleCreateFile}
        onDownloadFile={handleDownloadFile}
        onLinkCopy={publicFiles ? handlePublicLinkCopy : undefined}
        onDeleteFile={handleDeleteFile}
        onDeleteFolder={handleDeleteFolder}
        canDownloadFile

        /*
          onMoveFolder={handleRenameFolder}
          onMoveFile={handleRenameFile}
          onRenameFolder={handleRenameFolder}
          onRenameFile={handleRenameFile}
        */
      />
      <input name="file" type="file" hidden onChange={uploadFile} ref={inputFileRef} multiple />
    </div>
  )
}

export default connect(mapStateToProps)(Files)
