const actions = {
  RESET: 'jobs_schedules/RESET',
  SET_STATE: 'jobs_schedules/SET_STATE',
  SET_JOBS_FILTERS: 'jobs_schedules/SET_JOBS_FILTERS',
  DELETE_JOBS_FILTERS: 'jobs_schedules/DELETE_JOBS_FILTERS',
  JOBS_SET_SELECTED_ROWS: 'jobs_schedules/jobs/SET_SELECTED_ROWS',
  JOBS_SET_EXPANDED_ROWS: 'jobs_schedules/jobs/SET_EXPANDED_ROWS',
  JOBRUNS_SET_SELECTED_ROWS: 'jobs_schedules/jobruns/SET_SELECTED_ROWS',
  TASKRUNS_SET_SELECTED_ROWS: 'jobs_schedules/taskruns/SET_SELECTED_ROWS',
}

export default actions
