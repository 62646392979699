import React, { useEffect, useState } from 'react'
import { Button, Col, List, Row } from 'antd'
import { SaveOutlined } from '@ant-design/icons'
import { getIntl } from 'localization'
import { checkObjectTrue } from 'services/utilities'
import { v4 as uuidv4 } from 'uuid'
import { connect } from 'react-redux'
import { checkLocalDuplicate, checkScenarioParameterDuplicate, emptyNameCheck } from './validations'
import ParameterItem from './ParameterItem'
import { importer } from './helpers'

const initialParam = { key: 999, name: '', type: 'string', value: '', new: true }

const Parameters = ({ active_scenario, value, onChange, router, dispatch, container }) => {
  const { taskId } = router.location.query

  const scenario_parameters = active_scenario?.content?.scenario_parameters || []

  const taskParameters =
    taskId && active_scenario.content.chart.nodes[taskId]
      ? active_scenario.content.chart.nodes[taskId].properties.parameters
      : []

  const [newParameterValues, setNewParameterValues] = useState(initialParam)
  const [parameters, setParameters] = useState([])
  const [isChanged, setIsChanged] = useState(false)

  const handleParameterSet = params => {
    if (onChange) onChange(params)
  }

  const dataSwitcher = () => {
    switch (container) {
      case 'scenario':
        setParameters(scenario_parameters)
        break
      case 'task':
        setParameters(taskParameters)
        break
      case 'library':
        setParameters(importer(value))
        break
      default:
        break
    }
  }
  useEffect(() => {
    dataSwitcher()
  }, [container])

  useEffect(() => {
    dataSwitcher()
  }, [active_scenario.id, taskId, value])

  const validateItem = items => {
    const { new_item, original_item } = items
    if (container === 'library') return new_item
    const validations = {
      empty_name_check: emptyNameCheck(parameters, new_item),
      duplicate_name_check: !checkLocalDuplicate(parameters, new_item),
    }

    // Additional validation check(s) for task scope
    if (container === 'task') {
      Object.assign(validations, {
        duplicate_scenario_scope_name_check: !checkScenarioParameterDuplicate(
          scenario_parameters,
          new_item,
        ),
      })
    }

    const validated = checkObjectTrue(validations)
    return validated ? new_item : original_item
  }

  const handleParameterChange = item => {
    const newParameters = [...parameters]
    const index = newParameters.findIndex(p => p.key === item.key)
    validateItem({ new_item: item, original_item: parameters[index] })
    newParameters[index] = item
    setParameters(newParameters)
    if (container === 'library') {
      const mappedParameters = newParameters.map(({ key, name, type, value }) => ({
        key,
        name,
        type,
        value,
      }))
      handleParameterSet(mappedParameters)
    }
    setIsChanged(true)
  }

  const handleParameterDelete = key => {
    const deleted_parameters = parameters.filter(parameter => parameter.key !== key)
    setParameters([...deleted_parameters])
    handleParameterSet([...deleted_parameters])
    setIsChanged(true)
  }

  const handleAddNewItem = () => {
    const validated_item = validateItem({ new_item: newParameterValues, original_item: false })
    if (validated_item) {
      const changedParameters = [
        ...parameters,
        { ...newParameterValues, key: uuidv4(), new: false },
      ]
      setParameters(changedParameters)
      if (container === 'library') {
        const mappedParameters = changedParameters.map(({ key, name, type, value }) => ({
          key,
          name,
          type,
          value,
        }))

        handleParameterSet(mappedParameters)
      }
      setNewParameterValues({ ...initialParam, type: newParameterValues.type })
      setIsChanged(true)
    }
  }

  const saveParameters = () => {
    switch (container) {
      case 'scenario':
        dispatch({
          type: 'active_scenario/SET_SCENARIO_PARAMETERS',
          payload: { parameters },
        })
        break
      case 'task':
        dispatch({
          type: 'active_scenario/SET_TASK_PARAMETERS',
          payload: { parameters, taskId },
        })
        break
      default:
        break
    }
    setIsChanged(false)
  }

  const evenStyle = { backgroundColor: '#FAFAFA' }
  const rowStyle = { padding: 0 }
  return (
    <Row gutter={[8, 8]}>
      <Col span={24}>
        <List
          size="small"
          locale={{ emptyText: getIntl('parameter_list.empty') }}
          bordered
          dataSource={parameters}
          renderItem={(item, index) => {
            return (
              <List.Item style={index % 2 === 0 ? { ...rowStyle, ...evenStyle } : rowStyle}>
                <ParameterItem
                  library={container === 'library'}
                  item={item}
                  setData={handleParameterChange}
                  deleteParameterItem={handleParameterDelete}
                />
              </List.Item>
            )
          }}
        />
      </Col>
      <Col span={24}>
        <List.Item>
          <ParameterItem
            item={newParameterValues}
            setData={setNewParameterValues}
            addNewItem={handleAddNewItem}
            library={container === 'library'}
          />
        </List.Item>
      </Col>
      <Col span={24}>
        {container !== 'library' && (
          <List.Item>
            <Button
              disabled={!isChanged}
              icon={<SaveOutlined />}
              type="primary"
              onClick={saveParameters}
            >
              {getIntl('parameter_list.button.save_parameters')}
            </Button>
          </List.Item>
        )}
      </Col>
    </Row>
  )
}
const mapStateToProps = ({ active_scenario, active_task, settings, router }, ownProps) => ({
  active_scenario,
  active_task,
  settings,
  router,
  ...ownProps,
})
export default connect(mapStateToProps)(Parameters)
