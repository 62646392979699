import React, { useState } from 'react'
import { Button, Tooltip, Tag, App } from 'antd'
import { getIntl } from 'localization'
import {
  addRobot,
  removeRobot,
  startRobot,
  stopRobot,
  syncRobot,
  getRobot,
} from 'utils/robotOperationHelper'
import { ApiOutlined, SyncOutlined } from '@ant-design/icons'
import Robot from 'models/Robot'
import { FormattedMessage } from 'react-intl'
import { API_URL } from 'index'

const RobotActions = ({ data, localRobots, reload, small }) => {
  const url =
    process.env.NODE_ENV === 'production' ? `https://api.${window.location.hostname}/api` : API_URL
  const [processing, setProcessing] = useState(false)
  const [checking, setChecking] = useState(false)
  const [disabled, setDisabled] = useState(false)

  const { api_key, status, name } = data
  const localRobotData = localRobots?.find(robot => robot.name === name) || {}
  const { id: localRobotID, status: localStatus } = localRobotData
  const { notification } = App.useApp()
  const actionStatusHandler = action => {
    setProcessing(action || false)
    setDisabled(!!action)
  }
  const getAPIKey = async () => {
    const robot = await getRobot(data.id)
    const { api_key } = new Robot(robot)
    return api_key
  }

  const refreshList = async () => {
    setChecking(true)
    reload()
    setTimeout(() => setChecking(false), 2000)
  }
  const handleConnectRobot = async () => {
    actionStatusHandler('connect')
    addRobot({ api_url: url, api_key: api_key || (await getAPIKey()) })
      .then(success => {
        notification.success({ message: getIntl('notifications.localRobots.success.connect') })
        refreshList()
      })
      .catch(err => {
        notification.error({
          message: getIntl('notifications.localRobots.error.connect'),
          // description: JSON.stringify(err.response.data)
        })
      })
      .finally(() => {
        actionStatusHandler()
      })
  }

  const handleSyncRobot = () => {
    actionStatusHandler('sync')
    syncRobot({ id: localRobotID }) //
      .then(success => {
        notification.success({
          message: getIntl('notifications.localRobots.success.sync'),
        })
        refreshList()
      })
      .catch(err => {
        notification.error({
          message: getIntl('notifications.localRobots.error.sync'),
          description: JSON.stringify(err),
        })
      })
      .finally(() => {
        actionStatusHandler()
      })
  }

  const handleStartRobot = () => {
    actionStatusHandler('start')
    startRobot({ id: localRobotID }) //
      .then(success => {
        notification.success({ message: getIntl('notifications.localRobots.success.start') })
        refreshList()
      })
      .catch(err => {
        notification.error({
          message: getIntl('notifications.localRobots.error.start'),
          description: JSON.stringify(err),
        })
      })
      .finally(() => {
        actionStatusHandler()
      })
  }
  const handleStopRobot = () => {
    actionStatusHandler('stop')
    stopRobot({ id: localRobotID }) //
      .then(success => {
        notification.success({ message: getIntl('notifications.localRobots.success.stop') })
        refreshList()
      })
      .catch(err => {
        notification.error({
          message: getIntl('notifications.localRobots.error.stop'),
          description: JSON.stringify(err),
        })
      })
      .finally(() => {
        actionStatusHandler()
      })
  }
  const handleRemoveRobot = () => {
    actionStatusHandler('disconnect')
    removeRobot({ id: localRobotID }) //
      .then(success => {
        notification.success({ message: getIntl('notifications.localRobots.success.disconnect') })
        refreshList()
      })
      .catch(err => {
        notification.error({
          message: getIntl('notifications.localRobots.error.disconnect'),
          description: JSON.stringify(err),
        })
      })
      .finally(() => {
        actionStatusHandler()
      })
  }

  if (checking)
    return (
      <Tag color="blue">
        <FormattedMessage id="Checking" />
      </Tag>
    )

  if (status === 'Up' && !localRobotData?.id) {
    return <Tag color="orange">{getIntl('robotManager.robotList.connectedElsewhere')}</Tag>
  }
  return (
    <>
      {localRobotData?.id ? (
        <Button.Group className="float-center">
          {/**

            <Tooltip title={getIntl(processing === 'sync' ? 'tooltip.syncing' : 'tooltip.sync')}>
              <Button icon={<SyncOutlined spin={processing === 'sync'} />} disabled={disabled} onClick={handleSyncRobot} />
            </Tooltip>
            */}
          {localRobotData?.status !== 'UP' ? (
            <Tooltip title={getIntl(processing === 'start' ? 'tooltip.starting' : 'tooltip.start')}>
              <Button
                icon={processing === 'start' ? <SyncOutlined spin /> : <i className="icmn-play3" />}
                disabled={disabled}
                onClick={handleStartRobot}
              />
            </Tooltip>
          ) : (
            <Tooltip title={getIntl(processing === 'stop' ? 'tooltip.stopping' : 'tooltip.stop')}>
              <Button
                icon={processing === 'stop' ? <SyncOutlined spin /> : <i className="icmn-stop2" />}
                disabled={disabled}
                onClick={handleStopRobot}
              />
            </Tooltip>
          )}
          <Tooltip
            title={getIntl(
              processing === 'disconnect' ? 'tooltip.disconnecting' : 'tooltip.disconnect',
            )}
          >
            <Button
              danger
              icon={
                processing === 'disconnect' ? <SyncOutlined spin /> : <i className="icmn-bin" />
              }
              disabled={disabled}
              onClick={handleRemoveRobot}
            />
          </Tooltip>
        </Button.Group>
      ) : (
        <Tooltip
          title={getIntl(
            processing === 'connect'
              ? 'tooltip.connecting'
              : 'robotManager.robotList.useOnButton.buttonTitle',
          )}
        >
          <Button
            icon={processing === 'connect' ? <SyncOutlined spin /> : <ApiOutlined />}
            type="primary"
            disabled={disabled}
            onClick={() => handleConnectRobot()}
          />
        </Tooltip>
      )}
    </>
  )
}

export default RobotActions
