import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Button, Col, Modal, Row } from 'antd'
import { getIntl } from 'localization'
import { PermissionsTable } from 'services/permissions'
import ACL from 'components/system/Auth/ACL'
import RobotList from 'components/widgets/Robots/EnhancedRobotList'
import { checkRobotManager } from 'utils/robotOperationHelper'
import { ReloadOutlined } from '@ant-design/icons'
import NewRobot from 'components/widgets/Robots/NewRobot'
import ButtonGeneral from 'components/widgets/Buttons/ButtonGeneral'

const mapStateToProps = ({ active_scenario, robots, router }) => ({
  active_scenario,
  robots,
  router,
})

const RobotListContainer = ({
  active_scenario,
  robots: { readyRobots },
  router: {
    location: { query },
  },
  dispatch,
}) => {
  const [robotManagerAvailable, setRobotManagerAvailable] = useState(false)
  const [selectedRobots, setSelectedRobots] = useState([])
  const [loading, setLoading] = useState(false)
  const [newRobotMode, setNewRobotMode] = useState(false)

  const robotManagerControl = () => {
    setLoading(true)
    checkRobotManager()
      .then(available => {
        setRobotManagerAvailable(available)
        // checkMatch()
      })
      .catch(err => setRobotManagerAvailable(false))
      .finally(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    robotManagerControl()
  }, [])

  useEffect(() => {
    setSelectedRobots(active_scenario?.robots || [])
  }, [active_scenario.id])

  const actions = {
    dispatch,
    loadData: (page, pageSize) => {
      dispatch({
        type: 'robots/GET_ROBOTS',
        payload: { filters: { page, pageSize } },
      })
    },
    changeSelectedRobots: robots => {
      dispatch({
        type: 'active_scenario/SET_ROBOTS',
        payload: { robots },
      })
      setSelectedRobots(robots)
    },
  }

  const backToNormalMode = () => {
    actions.loadData()
    setNewRobotMode(false)
  }

  return (
    <Row gutter={[8, 8]}>
      <Col span={12}>
        <ACL allowed={PermissionsTable.robot_manager.create_new_robot} type="role">
          {newRobotMode ? (
            <ButtonGeneral
              icon="arrow-left"
              onClick={() => setNewRobotMode(false)}
              text={getIntl('Robots')}
            />
          ) : (
            <ButtonGeneral
              onClick={() => setNewRobotMode(true)}
              text={getIntl('robotManager.robotList.new_robot')}
            />
          )}
        </ACL>
      </Col>
      <Col span={12} className="text-right">
        <Button
          loading={loading}
          type="primary"
          icon={<ReloadOutlined />}
          onClick={() => {
            robotManagerControl()
          }}
        />
      </Col>
      <Col span={24} className="mt-2">
        {newRobotMode ? (
          <NewRobot fullWidth inModal onSuccess={backToNormalMode} />
        ) : (
          <RobotList
            robotManagerAvailable={robotManagerAvailable}
            selectedRobots={selectedRobots}
            setSelectedRobots={actions.changeSelectedRobots}
            setReadyRobots
            inModal
          />
        )}
      </Col>
    </Row>
  )
}

export default connect(mapStateToProps)(RobotListContainer)
