import { AimOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import { getIntl } from 'localization'
import React from 'react'

const OperationButtons = ({ id, defaultVersion, onVersionChange, onVersionDelete, onVersionShow }) => {
  return (
    <Button.Group>
      <Button icon={<EditOutlined />} onClick={onVersionShow}>
        {getIntl('scenarioVersionList.workOnThisVersion')}
      </Button>
      <Button
        type="primary"
        icon={<AimOutlined />}
        onClick={onVersionChange}
        disabled={defaultVersion === id}
      >
        {getIntl('scenarioVersionList.makeDefault')}
      </Button>
      <Button
        type="danger"
        icon={<DeleteOutlined />}
        disabled={defaultVersion === id}
        onClick={onVersionDelete}
      >
        {getIntl('Delete')}
      </Button>
    </Button.Group>
  )
}

export default OperationButtons
