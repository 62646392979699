import { FileOutlined } from '@ant-design/icons'
import { Button, Modal, Tooltip } from 'antd'
import Files from 'components/widgets/Files'
import React, { useState } from 'react'
import { FormattedHTMLMessage } from 'react-intl'
import { connect } from 'react-redux'
import './style.css'
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'

const FileManagerDialog = ({ active_scenario }) => {
  const [modalOpen, setModalOpen] = useState(false)
  const { xs } = useBreakpoint()

  return (
    <>
      <Modal
        title={<FormattedHTMLMessage id="Files" />}
        open={modalOpen}
        onCancel={() => setModalOpen(false)}
        width={xs ? '100%' : '60%'}
        footer={false}
      >
        <Files workspace="scenarios" id={active_scenario.id} />
      </Modal>
      <Tooltip title={<FormattedHTMLMessage id="Files" />}>
        <Button
          data-tour="filesButton"
          onClick={() => setModalOpen(true)}
          icon={<FileOutlined />}
        />
      </Tooltip>
    </>
  )
}
const mapsStateToProps = ({ active_scenario }) => ({ active_scenario })

export default connect(mapsStateToProps)(FileManagerDialog)
