import React from 'react'
import { getIntl } from 'localization'
import { connect } from 'react-redux'
import { MAX_PARAMETER_NAME_LENGTH, MAX_PARAMETER_VALUE_LENGTH } from 'constantValues'

const PARAMETER_TYPES = [
  { key: 'string', value: 'String' },
  { key: 'integer', value: 'Integer' },
  { key: 'float', value: 'Float' },
  { key: 'boolean', value: 'Boolean' },
  { key: 'array', value: 'Array' },
  { key: 'object', value: 'Object' },
]

const DataTypeInput = ({ item, onChange, library = false }) => {
  let component
  if (library) {
    return (
      <input
        type="text"
        maxLength={MAX_PARAMETER_VALUE_LENGTH}
        className="form-control rounded-0"
        value={item.value}
        placeholder={getIntl('parameter_list.value.placeholder.text')}
        onChange={e => onChange(e.target.value)}
      />
    )
  }
  switch (item.type) {
    case 'string':
      component = (
        <input
          maxLength={MAX_PARAMETER_VALUE_LENGTH}
          type="text"
          className="form-control rounded-0"
          value={item.value}
          placeholder={getIntl('parameter_list.value.placeholder.text')}
          onChange={e => onChange(e.target.value)}
        />
      )
      break
    case 'integer':
      component = (
        <input
          type="number"
          className="form-control rounded-0"
          value={item.value}
          placeholder={getIntl('parameter_list.value.placeholder.integer')}
          onChange={e => onChange(e.target.value)}
        />
      )
      break
    case 'float':
      component = (
        <input
          type="number"
          className="form-control rounded-0"
          value={item.value}
          placeholder={getIntl('parameter_list.value.placeholder.float')}
          onChange={e => onChange(e.target.value)}
        />
      )
      break
    case 'boolean':
      component = (
        <select
          className="form-control rounded-0"
          defaultValue={item.value.toString()}
          onChange={e => onChange(e.target.value === 'true')}
        >
          <option key="true" value="true">
            True
          </option>
          <option key="false" value="false">
            False
          </option>
        </select>
      )
      break
    case 'array':
      component = (
        <input
          type="text"
          className="form-control rounded-0"
          value={item.value.toString()}
          placeholder={getIntl('parameter_list.value.placeholder.array')}
          onChange={e => onChange(e.target.value)}
        />
      )
      break
    case 'object':
      component = (
        <input
          type="text"
          className="form-control rounded-0"
          value={item.value}
          placeholder={getIntl('parameter_list.value.placeholder.object')}
          onChange={e => onChange(e.target.value)}
        />
      )
      break
    default:
      component = (
        <input
          maxLength={MAX_PARAMETER_VALUE_LENGTH}
          type="text"
          className="form-control rounded-0"
          value={item.value}
          placeholder={getIntl('parameter_list.value.placeholder.text')}
          onChange={e => onChange(e.target.value)}
        />
      )
  }
  return component
}

const validateAndFormat = (item, library = false) => {
  if (library) {
    item.value = `${item.value}`
    return item
  }
  switch (item.type) {
    case 'string':
      item.value = `${item.value}`
      break
    case 'integer':
      item.value = parseInt(item.value, 10)
      break
    case 'float':
      item.value = parseFloat(item.value)
      break
    case 'array':
      // eslint-disable-next-line no-case-declarations
      const splitted = item.value.toString().split(',')
      item.value = item.value.length !== 0 ? [...splitted] : []
      break
    case 'boolean':
      if (typeof item.value !== 'boolean') item.value = true
      break
    case 'object':
      try {
        item.value = `${item.value}`
      } catch (e) {
        console.log('Not Valid JSON', item.value)
        item.value = `${item.value}`
      }
      break
    default:
      item.value = `${item.value}`
      break
  }

  return item
}

const ParameterItem = ({ item, setData, addNewItem, deleteParameterItem, library }) => {
  const { key, name, type } = item
  const changeData = (t, v) => {
    let d
    switch (t) {
      case 'name':
        d = validateAndFormat({ ...item, name: v }, library)
        break
      case 'type':
        d = validateAndFormat({ ...item, type: v, value: '' }, library)
        break
      case 'value':
        d = validateAndFormat({ ...item, value: v }, library)
        break
      default:
        break
    }
    setData(d)
  }

  const addItem = e => {
    e.preventDefault()
    addNewItem()
  }
  const deleteItem = (e, _key) => {
    e.preventDefault()
    deleteParameterItem(_key)
  }

  return (
    <div className="input-group input-group-sm align-middle">
      <input
        type="text"
        style={{ maxWidth: 300 }}
        maxLength={MAX_PARAMETER_NAME_LENGTH}
        className="form-control rounded-0 w-25"
        value={name}
        placeholder={getIntl('parameter_list.name.placeholder')}
        onChange={e => changeData('name', e.target.value)}
      />

      <select
        style={{ maxWidth: 100 }}
        className="custom-select rounded-0 w-25"
        defaultValue={type}
        onChange={e => changeData('type', e.target.value)}
      >
        {PARAMETER_TYPES.map(parameterType => {
          return (
            <option key={parameterType.key} value={parameterType.key}>
              {parameterType.value}
            </option>
          )
        })}
      </select>

      <DataTypeInput item={item} onChange={v => changeData('value', v)} library={library} />

      <div className="input-group-append">
        {item.new ? (
          <button type="button" className="btn btn-success btn-sm rounded-0" onClick={addItem}>
            <i className="fa fa-plus" /> {!library && getIntl('Add')}
          </button>
        ) : (
          <button
            type="button"
            className="btn btn-outline-danger btn-sm rounded-0"
            onClick={e => deleteItem(e, key)}
          >
            <i className="fa fa-trash-o" /> {!library && getIntl('Delete')}
          </button>
        )}
      </div>
    </div>
  )
}
const mapStateToProps = state => ({ settings: state.settings })
export default connect(mapStateToProps)(ParameterItem)
