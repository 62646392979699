import React from 'react'
import { Typography } from 'antd'
import { FormattedHTMLMessage } from 'react-intl'

export const FteExtra = () => {
  return (
    <>
      <Typography.Text type="secondary">
        <FormattedHTMLMessage id="scenarioDesigner.settings.actualEffort.inputInfo" />
      </Typography.Text>
      <br />
      <Typography.Text type="secondary">
        <FormattedHTMLMessage id="scenarioDesigner.settings.actualEffort.defaultFTEInfo" />
      </Typography.Text>
    </>
  )
}
export const ActualEffortSuffix = () => {
  return (
    <Typography.Text type="secondary">
      <FormattedHTMLMessage id="scenarioDesigner.settings.actualEffort.unit" />
    </Typography.Text>
  )
}
