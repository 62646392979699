import { all } from 'redux-saga/effects'
import user from './user/sagas'
import menu from './menu/sagas'
import scenarios from './scenarios/sagas'
import active_scenario from './active_scenario/sagas'
import active_step from './active_step/sagas'
import tasks from './tasks/sagas'
import files from './files/sagas'
import libraries from './libraries/sagas'
import chart from './chart/sagas'
import settings from './settings/sagas'
import robots from './robots/sagas'
import organization_users from './organization_users/sagas'
import teams from './teams/sagas'
import organizations from './organizations/sagas'
import jobruns from './jobruns/sagas'
import taskruns from './taskruns/sagas'
import jobs from './jobs/sagas'
import news from './news/sagas'
import active_robot from './active_robot/sagas'
import scenario_editor from './scenario_editor/sagas'
import jobs_schedules from './jobs_schedules/sagas'
import breadcrumbs from './breadcrumbs/sagas'
import statistics from './statistics/sagas'
import logs from './logs/sagas'
import reports from './reports/sagas'
import catalog_items from './catalog_items/sagas'
import marketplace from './marketplace/sagas'
import documentation from './documentation/sagas'
import invoices from './invoices/sagas'
import package_orders from './package_orders/sagas'
import packages from './packages/sagas'
import cards from './cards/sagas'
import plans from './plans/sagas'
import vaults from './vaults/sagas'
import notifications from './notifications/sagas'
import agreements from './agreements/sagas'
import tours from './tours/sagas'
import queues from './queues/sagas'
import internal_notification from './internal_notification/sagas'

export default function* rootSaga() {
  yield all([
    user(),
    menu(),
    settings(),
    scenario_editor(),
    jobs_schedules(),
    organizations(),
    scenarios(),
    robots(),
    organization_users(),
    teams(),
    jobs(),
    jobruns(),
    taskruns(),
    news(),
    active_scenario(),
    active_step(),
    active_robot(),
    tasks(),
    files(),
    libraries(),
    chart(),
    breadcrumbs(),
    statistics(),
    logs(),
    reports(),
    catalog_items(),
    marketplace(),
    documentation(),
    invoices(),
    package_orders(),
    packages(),
    cards(),
    plans(),
    vaults(),
    notifications(),
    agreements(),
    queues(),
    tours(),
    internal_notification(),
  ])
}
