import { List } from 'antd'
import React, { useState, useEffect } from 'react'
import style from '../style.module.scss'
import { dividerComponent, headerComponent, linkComponent } from './ListComponents'

const itemStyle = { fontSize: '1em', fontWeight: '500', lineHeight: '1em', border: 'none' }

const Resources = () => {
  const dataSource = [
    {
      key: 'title',
      style: { fontSize: '1.2em', fontWeight: 'bolder', lineHeight: undefined },
      link: '',
      component: headerComponent,
      text: 'Resources',
    },
    {
      key: 'documentation',
      link: 'landingPage.footerLinks.documentation',
      component: linkComponent,
      text: 'Documentation',
    },
    {
      key: 'blog',
      link: 'landingPage.footerLinks.blog',
      component: linkComponent,
      text: 'Blog',
      newPage: true,
    },
    {
      key: 'slack',
      link: 'landingPage.footerLinks.slack',
      component: linkComponent,
      text: 'Slack',
    },
    {
      key: 'discord',
      link: 'landingPage.footerLinks.discord',
      component: linkComponent,
      text: 'Discord',
      newPage: true,
    },
    {
      key: 'faq',
      link: 'landingPage.footerLinks.faq',
      component: linkComponent,
      text: 'FAQ',
      newPage: true,
    },
    { key: 'd1', component: dividerComponent },
    {
      key: 'academy',
      link: 'landingPage.footerLinks.academy',
      component: linkComponent,
      text: 'Kronnika Academy',
      className: style.kronnikaPink,
      newPage: true,
    },
    { key: 'd2', component: dividerComponent },
    {
      key: 'partners',
      link: 'landingPage.footerLinks.partners',
      component: linkComponent,
      text: 'Partners',
      className: style.kronnikaPink,
      newPage: true,
    },
  ]

  const renderItem = ({
    key,
    className = '',
    style = '',
    link = '',
    text = '',
    component,
    isFunction = true,
    newPage = false,
    searchType,
  }) => (
    <List.Item item={key} style={{ ...itemStyle, ...style }}>
      {isFunction ? (
        component({ className, link, text, key, newPage, searchType })
      ) : (
        <>{component}</>
      )}
    </List.Item>
  )

  return <List size="small" bordered={false} dataSource={dataSource} renderItem={renderItem} />
}

export default Resources
