import { RobotOutlined } from '@ant-design/icons'
import { Button, Modal, Tooltip } from 'antd'
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'
import ACL from 'components/system/Auth/ACL'
import RobotListContainer from 'pages/scenario-designer/Scenes/components/RobotListContainer'
import React, { useState } from 'react'
import { FormattedHTMLMessage } from 'react-intl'
import { PermissionsTable as pt } from 'services/permissions'

const RobotManagerDialog = () => {
  const [modalOpen, setModalOpen] = useState(false)
  const { xs } = useBreakpoint()

  return (
    <ACL allowed={pt.robot_manager.robot_list} type="role">
      <Modal
        open={modalOpen}
        onCancel={() => setModalOpen(false)}
        title={<FormattedHTMLMessage id="Robot Manager" />}
        footer={false}
        width={xs ? '100%' : '60%'}
      >
        <RobotListContainer />
      </Modal>
      <Tooltip title={<FormattedHTMLMessage id="Robot Manager" />}>
        <Button
          data-tour="robotsButton"
          type="primary"
          onClick={() => setModalOpen(true)}
          icon={<RobotOutlined />}
        />
      </Tooltip>
    </ACL>
  )
}

export default RobotManagerDialog
