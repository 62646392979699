export const PermissionsTable = {
  all_users: [
    'super',
    'admin',
    'marketadmin',
    'reseller',
    'manager',
    'power',
    'developer',
    'user',
    'saasuser',
  ],
  developerModeMembers: ['super', 'admin', 'marketadmin', 'manager', 'power', 'developer'],
  dashboard: {
    overall: [
      'super',
      'admin',
      'marketadmin',
      'reseller',
      'manager',
      'power',
      'developer',
      'user',
      'saasuser',
    ],
  },
  scenario_designer: {
    main_menu: [
      'super',
      'admin',
      'marketadmin',
      'reseller',
      'manager',
      'power',
      'developer',
      'user',
    ],
    all: [
      'super',
      'admin',
      'marketadmin',
      'reseller',
      'manager',
      'power',
      'developer',
      'user',
      'saasuser',
    ],
    limited: ['super', 'admin', 'marketadmin', 'reseller', 'manager', 'power', 'developer'],
  },
  jobs_schedules: {
    main_menu: [
      'super',
      'admin',
      'marketadmin',
      'reseller',
      'manager',
      'power',
      'developer',
      'user',
      'saasuser',
    ],
    all: [
      'super',
      'admin',
      'marketadmin',
      'reseller',
      'manager',
      'power',
      'developer',
      'user',
      'saasuser',
    ],
    limited: ['super', 'admin', 'marketadmin', 'reseller', 'manager', 'power', 'developer'],
  },
  queues: {
    all: ['super', 'admin', 'marketadmin', 'reseller', 'manager', 'power', 'developer', 'user'],
  },
  robot_manager: {
    main_menu: ['super', 'admin', 'marketadmin', 'reseller', 'manager', 'power', 'developer'],
    robot_list: ['super', 'admin', 'marketadmin', 'reseller', 'manager', 'power', 'developer'],
    robot_profile: ['super', 'admin', 'marketadmin', 'reseller', 'manager', 'power', 'developer'],
    create_new_robot: [
      'super',
      'admin',
      'marketadmin',
      'reseller',
      'manager',
      'power',
      'developer',
    ],
    delete_robot: ['super', 'admin', 'marketadmin', 'reseller', 'manager', 'power'],
  },
  company_catalog: {
    manage: ['super', 'admin', 'marketadmin', 'reseller', 'manager', 'power'],
    view_use: [
      'super',
      'admin',
      'marketadmin',
      'reseller',
      'manager',
      'power',
      'developer',
      'user',
    ],
    new_item: ['super', 'admin', 'marketadmin', 'reseller', 'manager', 'power', 'developer'],
    delete_item: ['super', 'admin', 'marketadmin', 'reseller', 'manager', 'power'],
    publisher: ['super', 'admin', 'marketadmin'],
    publish_on_market: [
      'super',
      'admin',
      'marketadmin',
      'reseller',
      'manager',
      'power',
      'developer',
    ],
  },
  robot_marketplace: {
    pendings: ['super', 'admin', 'marketadmin'],
    manage: ['super', 'admin', 'marketadmin'],
    editors: ['super', 'admin', 'marketadmin', 'manager', 'power', 'developer'],
    edit_item: ['super', 'admin', 'marketadmin', 'reseller', 'manager', 'power', 'developer'],
    view_use: [
      'super',
      'admin',
      'marketadmin',
      'reseller',
      'manager',
      'power',
      'developer',
      'user',
      'saasuser',
    ],
    new_item: ['super', 'admin', 'marketadmin', 'reseller', 'manager', 'power', 'developer'],
    my_robots_filter: [
      'super',
      'admin',
      'marketadmin',
      'reseller',
      'manager',
      'power',
      'developer',
    ],
    tabs_saasuser_filter: [
      'super',
      'admin',
      'marketadmin',
      'reseller',
      'manager',
      'power',
      'developer',
      'user',
    ],
  },
  reports: {
    all: ['super', 'admin', 'marketadmin'],
    reseller_assigned: ['super', 'admin', 'marketadmin', 'reseller'],
    my_organization: ['super', 'admin', 'marketadmin', 'reseller', 'manager'],
    my_jobs: [
      'super',
      'admin',
      'marketadmin',
      'reseller',
      'manager',
      'power',
      'developer',
      'user',
      'saasuser',
    ],
  },
  organization_management: {
    main_menu: ['super', 'admin', 'marketadmin', 'reseller', 'manager', 'saasuser'],
    manage_reseller: ['super', 'admin', 'marketadmin'],
    manage_organizations: ['super', 'admin', 'marketadmin', 'reseller'],
    my_organization: ['super', 'admin', 'marketadmin', 'reseller', 'manager', 'saasuser'],
    manage_teams: ['super', 'admin', 'marketadmin', 'reseller', 'manager'],
    manage_users: ['super', 'admin', 'marketadmin', 'reseller', 'manager'],
    organization_credentials_for_cloud_services: [
      'super',
      'admin',
      'marketadmin',
      'reseller',
      'manager',
    ],
  },
  subscription_management: {
    all: ['super', 'admin', 'marketadmin'],
    sub_organizations: ['super', 'admin', 'marketadmin', 'reseller'],
  },
  billing: {
    all: ['super', 'admin', 'marketadmin', 'reseller', 'manager', 'saasuser'],
    sub_organizations: ['super', 'admin', 'marketadmin', 'reseller'],
  },
  payouts: {
    default: ['super', 'admin', 'marketadmin', 'reseller', 'manager', 'power', 'developer'],
    menu: ['super', 'admin', 'marketadmin', 'reseller', 'manager', 'power', 'developer'],
  },
  vault: {
    deleteButton: ['super', 'admin', 'marketadmin', 'reseller', 'manager', 'power'],
  },
  info_support: {
    all: [
      'super',
      'admin',
      'marketadmin',
      'reseller',
      'manager',
      'power',
      'developer',
      'user',
      'saasuser',
    ],
    limited: ['super', 'admin', 'marketadmin', 'reseller', 'manager', 'power', 'developer'],
    modify: ['super', 'admin', 'marketadmin'],
  },
  analytics: {
    limited: ['super', 'admin', 'marketadmin', 'reseller', 'manager', 'power', 'developer', 'user'],
  },
  sub_hierarchy: role => {
    const all = [
      'super',
      'admin',
      'marketadmin',
      'reseller',
      'manager',
      'power',
      'developer',
      'user',
      'saasuser',
    ]
    const role_index = all.findIndex(r => r === role)
    return all.slice(role_index)
  },
}

/**
 * Returns a list with user roles below the given role's hierarchy
 * @param {string} role -  The role to look below
 * @returns {Array<string>} - Sub Hierarchy Roles
 */
export const subHierarchy = role => {
  const role_index = PermissionsTable.all_users.findIndex(r => r === role)
  return PermissionsTable.all_users.slice(role_index)
}
