import { Col, Row, Table, Button, Modal, Tag, App, List, Tooltip } from 'antd'
import React, { useEffect, useState } from 'react'
import { FormattedHTMLMessage } from 'react-intl'
import { connect } from 'react-redux'
import { DEFAULT_TABLE_PAGE_SIZE } from 'services/utilities'
import URLParamChanger from 'utils/urlParamChanger'
import NewTriggerSection from './NewTriggerSection'
import { getTriggers } from 'services/trigger'
import { getIntl } from 'localization'
import { DeleteOutlined, ThunderboltOutlined } from '@ant-design/icons'
import ButtonGeneral from '../Buttons/ButtonGeneral'
import { deleteRecord } from 'api/requests'
import conditionTranslator from './components/utilities'
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'

const mapStateToProps = ({ active_scenario, settings }) => ({
  active_scenario,
  settings,
})

const Triggers = ({ active_scenario }) => {
  const [modalOpen, setModalOpen] = useState(false)
  const [triggers, setTriggers] = useState({ count: 0, results: [] })
  const [loading, setLoading] = useState(false)
  const [newTriggerMode, setNewTriggerMode] = useState(false)
  const { modal } = App.useApp()
  const deleteTrigger = async id => {
    return new Promise((resolve, reject) => {
      deleteRecord('triggers', id).then(({ success, error }) => {
        if (!success) {
          modal.info({ content: error.message })
          reject(error)
        }
        getTriggerData()
        resolve(true)
      })
    })
  }

  const handleDeleteTrigger = async id => {
    modal.confirm({
      title: getIntl('confirmation.general.areYouSure'),
      onOk: () => deleteTrigger(id),
    })
  }

  const columns = [
    {
      dataIndex: 'name',
      width: '200px',
      fixed: 'left',
      ellipsis: true,
      title: <FormattedHTMLMessage id="Name" />,
    },
    {
      dataIndex: 'scenarioversion',
      ellipsis: true,
      title: <FormattedHTMLMessage id="Scenario Version" />,
      render: data => `${data.id}-${data.name}`,
    },
    {
      dataIndex: 'vault',
      ellipsis: true,
      title: <FormattedHTMLMessage id="Vault" />,
    },
    {
      dataIndex: 'robots',
      ellipsis: true,
      width: '300px',
      title: <FormattedHTMLMessage id="Robots" />,
      render: data => data.map(d => <Tag key={d.id}>{d.name}</Tag>),
    },
    {
      dataIndex: 'execute_by_owner',
      ellipsis: true,
      title: <FormattedHTMLMessage id="Execute By Owner" />,
      render: data => <FormattedHTMLMessage id={data} />,
    },
    {
      dataIndex: 'received_by',
      ellipsis: true,
      title: <FormattedHTMLMessage id="Received By" />,
    },
    {
      dataIndex: 'control_interval',
      ellipsis: true,
      title: <FormattedHTMLMessage id="Control Interval" />,
      render: data => (
        <>
          {data} <FormattedHTMLMessage id="short.minute" />
        </>
      ),
    },
    {
      dataIndex: 'operator',
      fixed: 'left',
      ellipsis: true,
      title: <FormattedHTMLMessage id="Operator" />,
      render: operator => <FormattedHTMLMessage id={operator} />,
    },
    {
      dataIndex: 'activities',
      ellipsis: true,
      title: <FormattedHTMLMessage id="Rules" />,
      render: (data, row) =>
        data.map(d => (
          <Tag
            style={{ cursor: 'pointer' }}
            key={d.id}
            onClick={() => {
              modal.info({
                title: <FormattedHTMLMessage id="Rules" />,
                content: (
                  <Row>
                    <Col>
                      <List
                        dataSource={data}
                        renderItem={i => (
                          <List.Item>
                            <Row>
                              <Col span={24}>
                                <strong>{i.name}</strong>
                              </Col>
                              <Col span={24}>
                                <u>
                                  {i.handler_name} - {i.ruleset} - {i.expression}
                                </u>
                              </Col>
                              <Col span={24}>
                                <ul>
                                  {Object.keys(i.params).map(key => {
                                    let strr = `${key} : `

                                    if (i.params[key].condition) {
                                      strr += ` ${conditionTranslator(i.params[key].condition)}`
                                    }

                                    if (i.params[key].value) {
                                      strr += ` ${i.params[key].value}`
                                    }

                                    return <li>{strr}</li>
                                  })}
                                </ul>
                              </Col>
                            </Row>
                          </List.Item>
                        )}
                      />
                    </Col>
                  </Row>
                ),
              })
            }}
          >
            {d.name}
          </Tag>
        )),
    },
    {
      dataIndex: 'id',
      fixed: 'right',
      render: id => (
        <Button
          size="small"
          type="primary"
          icon={<DeleteOutlined />}
          onClick={() => handleDeleteTrigger(id)}
        />
      ),
    },
  ]

  const pagination = {
    total: triggers.count,
    pageSize: DEFAULT_TABLE_PAGE_SIZE,
    // current: page || DEFAULT_TABLE_ACTIVE_PAGE,
    onChange: page => {
      URLParamChanger({ page })
    },
  }

  const getTriggerData = async () => {
    setLoading(true)
    const triggers = await getTriggers()
    setTriggers({ count: triggers.length, results: triggers })
    setLoading(false)
  }

  const postSave = () => {
    getTriggerData()
    setNewTriggerMode(false)
  }

  const handleCancelTrigger = () => {
    setNewTriggerMode(false)
  }
  useEffect(() => {
    if (active_scenario.id) {
      getTriggerData()
    }
  }, [active_scenario.id])
  const { xs } = useBreakpoint()

  return (
    <>
      <Modal
        title={getIntl('Triggers')}
        open={modalOpen}
        onCancel={() => setModalOpen(false)}
        width={xs ? '100%' : '60%'}
        footer={null}
        destroyOnClose
      >
        <Row gutter={[8, 24]} justify="center">
          {!newTriggerMode && (
            <Col sm={24} className="text-right">
              <ButtonGeneral
                type="primary"
                icon={newTriggerMode ? 'list' : undefined}
                onClick={() => setNewTriggerMode(newTriggerMode => !newTriggerMode)}
                text={getIntl(!newTriggerMode ? 'Add New Trigger' : 'Trigger List')}
              />
            </Col>
          )}
          {newTriggerMode ? (
            <Col xs={24} sm={24}>
              <NewTriggerSection
                cancelNewTrigger={handleCancelTrigger}
                postSave={postSave}
                scenarioVersion={active_scenario.version_id}
              />
            </Col>
          ) : (
            <Col sm={24}>
              <Table
                size="small"
                dataSource={triggers.results}
                columns={columns}
                pagination={pagination}
                loading={loading}
                scroll={{
                  x: 'auto',
                }}
              />
            </Col>
          )}
        </Row>
      </Modal>
      <Tooltip title={getIntl('Triggers')}>
        <Button onClick={() => setModalOpen(true)} icon={<ThunderboltOutlined />} />
      </Tooltip>
    </>
  )
}

export default connect(mapStateToProps)(Triggers)
