import VaultIcon from 'components/CustomIcons/Vault'
import { PermissionsTable as pt } from 'services/permissions'

export default async function getMenuData() {
  return [
    {
      title: 'Dashboard',
      key: 'dashboard',
      icon: 'icmn-eye',
      url: '/dashboard',
      auth_users: pt.dashboard.overall,
    },
    {
      title: 'Designer',
      key: 'scenarioManager',
      icon: 'icmn-tree',
      url: '/scenario-designer/',
      auth_users: pt.scenario_designer.main_menu,
    },
    {
      title: 'Robot Manager',
      key: 'robotManager',
      icon: 'icmn-cogs',
      url: '/robot-manager/robots',
      auth_users: pt.robot_manager.main_menu,
    },
    {
      title: 'Jobs & Schedules',
      key: 'jobsNSchedules',
      icon: 'icmn-history',
      url: '/jobs-schedules',
      auth_users: pt.jobs_schedules.all,
    },
    {
      title: 'Company Catalog',
      key: 'catalogs',
      icon: 'icmn-stack',
      url: '/catalogs',
      auth_users: pt.company_catalog.view_use,
    },
    {
      title: 'Queues',
      key: 'queues',
      icon: 'icmn-move-down',
      url: '/queues',
      auth_users: pt.queues.all,
    },
    {
      title: 'Vaults',
      key: 'vaults',
      icon: 'icmn-shield',
      url: '/vaults',
      // auth_users: pt.vault.main_menu,
    },
    {
      title: 'Robot Marketplace',
      key: 'marketplace',
      icon: 'icmn-cart',
      url: '/market',
      auth_users: pt.robot_marketplace.view_use,
      disabled_urls: [],
    },
    {
      title: 'Info & Support',
      key: 'infoNSupport',
      icon: 'icmn-compass2',
      url: '/info-support',
      disabled_urls: [],
      auth_users: pt.info_support.all,
      children: [
        {
          title: 'Documentation',
          key: 'documentation',
          icon: 'icmn-book',
          url: '/info-support/knowledge-base',
          disabled_urls: [],
        },
        {
          title: 'Library Documentation',
          key: 'libraryDocumentation',
          icon: 'icmn-book',
          url: '/info-support/library-documentation',
          disabled_urls: [],
        },
        {
          title: 'Release Notes',
          key: 'releaseNotes',
          icon: 'icmn-list',
          url: '/info-support/release-notes',
          disabled_urls: [],
        },
        {
          title: 'Legal Documents',
          key: 'legalDocuments',
          icon: 'icmn-clipboard',
          url: '/info-support/legal-documents',
          disabled_urls: [],
        },
      ],
    },
    { divider: true, key: 'd1' },
    {
      title: 'Reports',
      key: 'reports',
      icon: 'icmn-stats-bars',
      url: '/reports/',
    },
    {
      title: 'Kronnika Analytics',
      key: 'analytics',
      icon: 'icmn-stats-dots',
      url: '/redirect/analytics',
      newTab: true,
    },
    { divider: true, key: 'd2' },
    {
      title: 'Organization Management',
      key: 'organizationManagement',
      icon: 'icmn-office',
      auth_users: pt.organization_management.main_menu,
      children: [
        {
          title: 'Manage Organizations',
          key: 'manageOrganizations',
          url: '/organization-management/manage-organizations',
          auth_users: pt.organization_management.manage_organizations,
        },
        {
          title: 'My Organization',
          key: 'myOrganization',
          url: '/organization-management/my-organization',
          auth_users: pt.organization_management.my_organization,
        },
        {
          title: 'Manage Users',
          key: 'mangeUsers',
          url: '/organization-management/manage-users',
          auth_users: pt.organization_management.manage_users,
        },
        {
          title: 'Manage Teams',
          key: 'mangeTeams',
          url: '/organization-management/manage-teams',
          auth_users: pt.organization_management.manage_teams,
        },
      ],
    },
    {
      title: 'Billing',
      key: 'billing',
      icon: 'icmn-file-text2',
      auth_users: pt.billing.all,
      children: [
        {
          title: 'Payment Methods',
          key: 'paymentMethods',
          icon: 'icmn-credit-card',
          url: '/payment-methods',
          auth_users: pt.billing.all,
        },
        {
          title: 'Payouts',
          key: 'payouts',
          icon: 'icmn-library',
          url: '/payouts',
          auth_users: pt.payouts.menu,
        },
        {
          title: 'Invoices',
          key: 'invoices',
          icon: 'icmn-file-text2',
          url: '/invoices',
          auth_users: pt.billing.all,
        },
        {
          title: 'My Packages',
          key: 'orders',
          icon: 'icmn-gift',
          url: '/my-packages',
          auth_users: pt.billing.all,
        },
        {
          title: 'Subscription',
          key: 'subscription',
          icon: 'icmn-gift',
          url: '/subscription-plans',
          disabled_urls: ['kronnika.io'],
          // auth_users: pt.subscription_management.all,
        },
      ],
    },
  ]
}
