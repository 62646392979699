import {
  CheckOutlined,
  ClockCircleOutlined,
  StopOutlined,
  SyncOutlined,
} from '@ant-design/icons'
import { Tag } from 'antd'
import React from 'react'
import { FormattedMessage } from 'react-intl'

const StatusTag = ({ status }) => {
  const statusColors = {
    down: 'red',
    up: 'green',
    busy: 'blue',
    pending: 'yellow',
    done: 'green',
    failed: 'red',
    inprogress: 'blue',
    transfer_started: 'blue',
  }
  return (
    <Tag color={statusColors[status.toLowerCase()] || 'default'}>
      <FormattedMessage id={status} />
    </Tag>
  )
}

export const JobStatusTag = ({ status }) => {

  const statusCheck = {
    STARTED: { color: 'blue', label: 'RUNNING', icon: <SyncOutlined spin /> },
    SUCCESS: { color: 'green', label: 'SUCCESS', icon: <CheckOutlined /> },
    PENDING: { color: 'orange', label: 'PENDING', icon: <ClockCircleOutlined /> },
    CANCELLED: { color: 'red', label: 'CANCELLED', icon: <StopOutlined /> },
    FAILURE: { color: 'red', label: 'FAILURE', icon: <StopOutlined /> },
  }

  return statusCheck[status] ? (
    <Tag icon={statusCheck[status]?.icon} color={statusCheck[status]?.color}>
      {<FormattedMessage id={statusCheck[status]?.label} />}
    </Tag>
  ) : <Tag>{status}</Tag>
}

export default StatusTag
