import { store } from 'index'

export const setBreadCrumbs = breadcrumbs => {
  store.dispatch({
    type: 'breadcrumbs/SET_CRUMBS',
    payload: breadcrumbs,
  })
}

const toProperCase = txt => {
  return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
}
const Breadcrumber = (path = '') => {
  const result = []

  let pathData = path.split('/')
  pathData = pathData[pathData.length - 1]
  pathData = pathData.split(' ')
  pathData.forEach(word => {
    result.push(toProperCase(word))
  })
  return result.join(' ')
}

export default Breadcrumber
