import React from 'react'
import { FormattedHTMLMessage } from 'react-intl'

const tours = {
  welcome: {
    disabled: false,
    steps: [],
  },
  interfaceTour: {
    disabled: false,
    steps: [
      {
        selector: '[data-tour="menuLeft"]',
        content: <FormattedHTMLMessage id="tours.interfaceTour.leftMenuInfo" />,
      },
      {
        selector: '[data-tour="leftMenu-robotManager"]',
        content: (
          <>
            <p>
              <FormattedHTMLMessage id="tours.interfaceTour.robotManager" />
            </p>
            <ul>
              <li>
                <p>
                  <FormattedHTMLMessage id="tours.interfaceTour.robotManager.li.setup" />
                </p>
              </li>
              <li>
                <p>
                  <FormattedHTMLMessage id="tours.interfaceTour.robotManager.li.create" />
                </p>
              </li>
            </ul>
          </>
        ),
      },
      {
        selector: '[data-tour="leftMenu-scenarioManager"]',
        content: <FormattedHTMLMessage id="tours.interfaceTour.scenarioManager" />,
      },

      {
        selector: '[data-tour="leftMenu-infoNSupport"]',
        content: (
          <>
            <p>
              <FormattedHTMLMessage id="tours.interfaceTour.infoNSupport" />,
            </p>
            <ul>
              <li>
                <p>
                  <FormattedHTMLMessage id="tours.interfaceTour.infoNSupport.li.create" />
                </p>
              </li>
              <li>
                <p>
                  <FormattedHTMLMessage id="tours.interfaceTour.infoNSupport.li.setup" />
                </p>
              </li>
            </ul>
          </>
        ),
      },
    ],
  },
  designerTour: {
    disabled: true,
    steps: [
      {
        selector: '[data-tour="designerInitPanel"]',
        content: <FormattedHTMLMessage id="tours.designerTour.scenarioDesignerInitial" />,
      },
      {
        selector: '[data-tour="newScenarioButton"]',
        content: <FormattedHTMLMessage id="tours.designerTour.newScenarioButton" />,
      },
      {
        selector: '[data-tour="openScenarioButton"]',
        content: <FormattedHTMLMessage id="tours.designerTour.openScenarioButton" />,
      },
    ],
  },
  designerInterfaceTour: {
    disabled: true,
    steps: [
      {
        selector: '[data-tour="scenarioOperationsMenu"]',
        content: <FormattedHTMLMessage id="tours.designerInterfaceTour.scenarioOperations" />,
      },
      /*       {
        selector: '[data-tour="marketMenu"]',
        content: <FormattedHTMLMessage id="tours.designerInterfaceTour.marketMenu" />,
      }, */
      {
        selector: '[data-tour="leftMenu-vaults"]',
        content: <FormattedHTMLMessage id="tours.designerInterfaceTour.vault" />,
      },
      {
        selector: '[data-tour="runButton"]',
        content: <FormattedHTMLMessage id="tours.designerInterfaceTour.runButton" />,
      },
      {
        selector: '[data-tour="scheduleButton"]',
        content: <FormattedHTMLMessage id="tours.designerInterfaceTour.scheduleButton" />,
      },
      {
        selector: '[data-tour="designerArea"]',
        content: <FormattedHTMLMessage id="tours.designerInterfaceTour.designerArea" />,
      },
      {
        selector: '[data-tour="settingsButton"]',
        content: <FormattedHTMLMessage id="tours.designerInterfaceTour.settingsButton" />,
      },
      {
        selector: '[data-tour="filesButton"]',
        content: <FormattedHTMLMessage id="tours.designerInterfaceTour.filesButton" />,
      },
      {
        selector: '[data-tour="parametersButton"]',
        content: <FormattedHTMLMessage id="tours.designerInterfaceTour.parametersButton" />,
      },
      {
        selector: '[data-tour="robotsButton"]',
        content: <FormattedHTMLMessage id="tours.designerInterfaceTour.robotsButton" />,
      },
      {
        selector: '[data-tour="previewDrawer"]',
        content: <FormattedHTMLMessage id="tours.designerInterfaceTour.previewDrawer" />,
      },
      {
        selector: '[data-tour="scenarioNameButton"]',
        content: <FormattedHTMLMessage id="tours.designerInterfaceTour.scenarioNameButton" />,
      },
      {
        selector: '[data-tour="scenarioInfoButton"]',
        content: <FormattedHTMLMessage id="tours.designerInterfaceTour.scenarioInfoButton" />,
      },
    ],
  },
  newScenarioTour: {
    disabled: true,
    steps: [
      {
        selector: '[data-tour="newScenarioName"]',
        content: <FormattedHTMLMessage id="tours.newScenarioTour.newScenarioName" />,
      },
      {
        selector: '[data-tour="fromCatalogCheckbox"]',
        content: <FormattedHTMLMessage id="tours.newScenarioTour.fromCatalogCheckbox" />,
      },
      {
        selector: '[data-tour="createScenarioButton"]',
        content: <FormattedHTMLMessage id="tours.newScenarioTour.createScenarioButton" />,
      },
    ],
  },
}

export default tours
