import React, { useRef, useState } from 'react'

import { Form, Dropdown, Button, Space, Modal, Input, Tooltip, App, Row, Col } from 'antd'
import { getIntl } from 'localization'
import { exportScenario } from 'utils/scenario_designer_utils'
import { connect } from 'react-redux'

import {
  ApartmentOutlined,
  DeleteOutlined,
  ExportOutlined,
  FolderOpenOutlined,
  ImportOutlined,
  PlusOutlined,
  SaveOutlined,
  ShopOutlined,
} from '@ant-design/icons'
import { getCatalogItems } from 'services/catalog_items'
import BlinkingSaveIcon from 'components/layout/SubBar/components/BlinkingSaveIcon'
import { FormattedHTMLMessage } from 'react-intl'
import CatalogOperations from './CatalogOperations'
import { deleteRecentlyOpenedScenario } from 'utils/recentScenarios'
import CatalogSelectorDialog from 'components/widgets/Dialogs/CatalogSelectorDialog'
import { getList } from 'api/requests'
import ScheduledJobList from './components/ScheduledJobList'
import MarketplaceOperations from './MarketplaceOperations'

import ScenarioVersionsDialog from 'components/widgets/Dialogs/ScenarioVersionsDialog'

const mapStateToProps = ({ active_scenario, organizations, scenario_editor, settings }) => ({
  active_scenario,
  organizations,
  scenario_editor,
  settings,
})

const ScenarioOperations = ({ active_scenario, organizations, scenario_editor, dispatch }) => {
  const [form] = Form.useForm()
  const [descriptionForm] = Form.useForm()
  const inputFileRef = useRef(null)
  const [mVisible, setMVisible] = useState(false)
  const { modal, notification } = App.useApp()
  const [visibilityCatalogItemList, setVisibilityCatalogItemList] = useState(false)
  const [visibilityCatalogModal, setVisibilityCatalogModal] = useState(false)
  const [visibilityMarketplaceModal, setVisibilityMarketplaceModal] = useState(false)
  const [versionsModalOpen, setVersionsModalOpen] = useState(false)

  const DescriptionModalContent = ({ description, onChange }) => {
    return (
      <Form form={descriptionForm}>
        <Form.Item
          name="description"
          rules={[
            {
              required: true,
              message: getIntl('formValidations.scenarioDesigner.description.required'),
            },
          ]}
        >
          <Input name="description" value={description} />
        </Form.Item>
      </Form>
    )
  }

  const checkExistingActiveScheduleJobs = async version_id => {
    return new Promise(resolve => {
      getList('jobs', {
        scenarioversion: version_id,
        is_scheduled: true,
        is_active: true,
      })
        .then(({ success, data }) => {
          if (success && data.count > 0) {
            modal.confirm({
              width: '60%',
              title: getIntl('dialogs.existingActiveScheduledJobs.title'),
              content: (
                <ScheduledJobList
                  jobs={data.results}
                  onAction={() => {
                    resolve(true)
                    Modal.destroyAll()
                  }}
                />
              ),
              footer: null,
              onCancel: () => resolve(false),
            })
          } else {
            resolve()
          }
        })
        .catch(e => {
          console.log(e)
          resolve(e)
        })
    })
  }

  const handleNewVersionCreate = async () => {
    const exisitingScheduledJobsConfirmed = await checkExistingActiveScheduleJobs(
      active_scenario.version_id,
    )
    setMVisible(false)
    modal.confirm({
      title: getIntl('scenario_designer.descriptionRequest.title'),
      okText: getIntl('Create'),
      content: <DescriptionModalContent description={active_scenario.description} />,
      onOk() {
        return new Promise((resolve, reject) => {
          descriptionForm
            .validateFields()
            .then(values => {
              dispatch({
                type: 'scenarios/SAVE_AS_NEW_SCENARIO_VERSION',
                payload: { description: values.description, is_default: true },
                redirect: true,
              })
              resolve()
            })
            .catch(error => {
              reject()
            })
        })
      },
    })
  }

  const checkRelatedCatalogItemExistence = async scenarioId => {
    const { success, data } = await getCatalogItems({
      filters: { scenario: scenarioId, is_active: true },
    })
    if (success && data.count > 0) {
      return true
    }
    return false
  }

  const handleScenarioDelete = async scenario_id => {
    dispatch({
      type: 'scenarios/DELETE_SCENARIO',
      payload: { scenario_id },
    })
    deleteRecentlyOpenedScenario(scenario_id)
  }

  const handleMenuClick = async e => {
    let existingCatalogItem = null
    switch (e.key) {
      case 'new':
        if (organizations.active_organization.is_suspended) {
          notification.warning({
            message: getIntl('dialogs.suspendedOrganization.title'),
            description: (
              <FormattedHTMLMessage id="dialogs.suspendedOrganization.scenarioContent" />
            ),
          })
          break
        }
        dispatch({
          type: 'scenario_editor/SET_STATE',
          payload: { scenarioPanelOpen: true, scenarioPanelMode: 'new' },
        })
        break
      case 'open':
        dispatch({
          type: 'scenario_editor/SET_STATE',
          payload: { scenarioPanelOpen: true, scenarioPanelMode: 'open' },
        })
        break
      case 'save':
        if (organizations.active_organization.is_suspended) {
          notification.warning({
            message: getIntl('dialogs.suspendedOrganization.title'),
            description: (
              <FormattedHTMLMessage id="dialogs.suspendedOrganization.scenarioContent" />
            ),
          })
          break
        }
        if (active_scenario.save_enabled) {
          dispatch({
            type: 'scenarios/SAVE_SCENARIO',
          })
        } else {
          setMVisible(true)
        }

        break
      case 'createNewVersion':
        if (organizations.active_organization.is_suspended) {
          notification.warning({
            message: getIntl('dialogs.suspendedOrganization.title'),
            description: <FormattedHTMLMessage id="dialogs.suspendedOrganization.robotContent" />,
          })
          break
        }

        handleNewVersionCreate()
        break
      case 'manageVersions':
        setVersionsModalOpen(true)
        break
      case 'delete':
        existingCatalogItem = await checkRelatedCatalogItemExistence(active_scenario.id)
        modal.confirm({
          title: getIntl('scenario_designer.delete_confirm.title'),
          content: existingCatalogItem
            ? getIntl('scenario_designer.delete_confirm.content.ForExistingCatalogItems')
            : null,
          onOk() {
            handleScenarioDelete(active_scenario.id)
          },
        })
        break
      case 'export':
        exportScenario(active_scenario)
        break
      case 'import':
        inputFileRef.current.click()
        break
      case 'add2Catalog':
        setVisibilityCatalogModal(true)
        break
      case 'getItem':
        setVisibilityCatalogItemList(true)
        break
      case 'add2market':
        if (active_scenario.market_save_enabled) {
          setVisibilityMarketplaceModal(true)
        } else {
          modal.info({
            title: getIntl('dialogs.scenario_designer.saveDisabled.title'),
            content: getIntl('dialogs.scenario_designer.saveDisabled.content'),
          })
        }
        break
      default:
    }
  }

  const MenuLabel = ({ label, shortcut }) => {
    return (
      <Row justify="space-between">
        <Col>{getIntl(label)}</Col>
        {shortcut && (
          <Col className="text-right">
            <small>{shortcut}</small>
          </Col>
        )}
      </Row>
    )
  }

  const items = [
    {
      key: 'new',
      icon: <PlusOutlined />,
      label: <MenuLabel label="New" />,
    },
    {
      key: 'open',
      icon: <FolderOpenOutlined />,
      label: <MenuLabel label="Open" />,
    },
    { key: 'save', icon: <SaveOutlined />, label: <MenuLabel label="Save" /> },
    { key: 'export', icon: <ExportOutlined />, label: <MenuLabel label="Export" /> },
    { key: 'import', icon: <ImportOutlined />, label: <MenuLabel label="Import" /> },
    {
      key: 'delete',
      icon: <DeleteOutlined />,
      label: active_scenario.save_enabled ? (
        <MenuLabel label="Delete" />
      ) : (
        <Tooltip title={getIntl('tooltip.scenarioversionInMarket.deleteDisabled')}>
          <MenuLabel label="Delete" />
        </Tooltip>
      ),
      disabled: !active_scenario.save_enabled,
    },
    { type: 'divider' },
    {
      type: 'group',
      label: getIntl('Versions'),
      children: [
        {
          key: 'createNewVersion',
          icon: <SaveOutlined />,
          label: <MenuLabel label="Create New Version" />,
        },
        {
          key: 'manageVersions',
          icon: <SaveOutlined />,
          label: <MenuLabel label="Manage Versions" />,
        },
      ],
    },

    { type: 'divider' },
    {
      type: 'group',
      label: getIntl('Catalog'),
      children: [
        {
          key: 'add2Catalog',
          icon: <PlusOutlined />,
          label: getIntl('catalog_operations_menu.add_scenario_to_catalog'),
        },
        {
          key: 'getItem',
          icon: <ImportOutlined />,
          label: getIntl('catalog_operations_menu.get_an_item'),
        },
      ],
    },
    { type: 'divider' },
    {
      type: 'group',
      label: getIntl('Marketplace'),
      children: [
        {
          key: 'add2market',
          icon: <ShopOutlined />,
          label: getIntl('catalog_operations_menu.add_to_marketplace'),
        },
      ],
    },
  ]
  const uploadFile = e => {
    const file = e.target.files[0]
    const reader = new FileReader()
    reader.onload = event => {
      const scenario_content = JSON.parse(event.target.result)

      dispatch({
        type: 'active_scenario/IMPORT_JSON',
        payload: { scenario_content },
      })
    }
    reader.readAsText(file)
  }

  return (
    <>
      <Space>
        <div data-tour="scenarioOperationsMenu">
          <Dropdown menu={{ items, onClick: handleMenuClick }} className="mr-2">
            <Button
              icon={scenario_editor.scenarioModified ? <BlinkingSaveIcon /> : <ApartmentOutlined />}
            >
              <FormattedHTMLMessage id="Scenario Operations" />
            </Button>
          </Dropdown>
        </div>
      </Space>
      <Form form={form}>
        <input name="file" type="file" hidden onChange={uploadFile} ref={inputFileRef} />
      </Form>
      <Modal
        open={mVisible}
        title={getIntl('dialogs.scenario_designer.saveDisabled.title')}
        onOk={handleNewVersionCreate}
        onCancel={() => setMVisible(false)}
        okText={getIntl('Create New Version')}
      >
        {getIntl('dialogs.scenario_designer.saveDisabled.content')}
      </Modal>
      <CatalogSelectorDialog
        visibility={visibilityCatalogItemList}
        setVisibility={setVisibilityCatalogItemList}
      />
      <CatalogOperations
        visibility={visibilityCatalogModal}
        setVisibility={setVisibilityCatalogModal}
      />
      <MarketplaceOperations
        visibility={visibilityMarketplaceModal}
        setVisibility={setVisibilityMarketplaceModal}
      />
      <ScenarioVersionsDialog modalOpen={versionsModalOpen} setModalOpen={setVersionsModalOpen} />
    </>
  )
}

export default connect(mapStateToProps)(ScenarioOperations)
