import React from 'react'

import ACL from 'components/system/Auth/ACL'
import { PermissionsTable } from 'services/permissions'
import ScenarioOperations from './ScenarioOperations'

const BasicOperations = () => {
  return (
    <ACL allowed={PermissionsTable.scenario_designer.limited} type="role">
      <ScenarioOperations />
    </ACL>
  )
}
export default BasicOperations
