import React from 'react'
import UserMenu from './UserMenu'
import UsageMenu from './UsageMenu'
import style from './style.module.scss'
import { filterRoutesByDisabledURLs } from 'services/utilities'
import NotificationsMenu from './NotificationsMenu'
import { connect } from 'react-redux'
import ResetLoginButton from './ResetLoginButton'
import BasicOperations from 'components/widgets/ScenarioDesigner/BasicOperations'
import JobActions from 'components/widgets/ScenarioDesigner/JobActions'
import NewQueueItemButton from '../SubBar/components/NewQueueItemButton'
import NewQueueButton from '../SubBar/components/NewQueueButton'
import ACL from 'components/system/Auth/ACL'
import { PermissionsTable } from 'services/permissions'
import { history } from 'index'
import { getIntl } from 'localization'
import { Breadcrumb } from 'antd'
import { HomeOutlined } from '@ant-design/icons'
import { FormattedHTMLMessage } from 'react-intl'
import Trigger from 'components/widgets/Trigger'
import RobotManagerDialog from 'components/widgets/Dialogs/RobotManagerDialog'
import FileManagerDialog from 'components/widgets/Dialogs/FileManagerDialog'
import ScenarioSettingsDialog from 'components/widgets/Dialogs/ScenarioSettingsDialog'
import ParametersDialog from 'components/widgets/Dialogs/ParametersDialog'

const mapStateToProps = ({ user, active_scenario, breadcrumbs, router }) => ({
  user,
  active_scenario,
  breadcrumbs: breadcrumbs.breadcrumbs,
  router,
})

const TopBar = ({ user, active_scenario, breadcrumbs, router }) => {
  const { pathname } = router.location

  return (
    <div className={style.topbar}>
      <div className="mr-md-4 mr-auto">
        <ul className={style.breadcrumbs}>
          {breadcrumbs &&
            breadcrumbs.length >= 0 &&
            !pathname.includes('scenario-designer') &&
            !pathname.includes('dashboard') && (
              <li className={style.breadcrumb}>
                <Breadcrumb
                  separator=">"
                  items={[
                    {
                      href: '/dashboard',
                      title: <HomeOutlined />,
                    },
                    ...breadcrumbs.map(breadcrumb => ({
                      title: breadcrumb.noTranslation ? (
                        breadcrumb.title
                      ) : (
                        <FormattedHTMLMessage id={breadcrumb?.title || 'utils.empty'} />
                      ),
                      href: breadcrumb.link,
                    })),
                  ]}
                />
              </li>
            )}
          {router.location.pathname.includes('scenario-designer') && active_scenario.id && (
            <>
              <li className={style.breadcrumb}>
                <BasicOperations />
              </li>
              <li className={style.breadcrumb}>
                <ScenarioSettingsDialog />
              </li>
              <li className={style.breadcrumb}>
                <FileManagerDialog />
              </li>
              <li className={style.breadcrumb}>
                <ParametersDialog />
              </li>
              <li className={style.breadcrumb}>
                <Trigger />
              </li>
              <li className={style.breadcrumb}>
                <RobotManagerDialog />
              </li>
              <li className={style.breadcrumb}>
                <JobActions />
              </li>
            </>
          )}
          <li className={style.breadcrumb}>
            <NewQueueItemButton pathname={pathname} />
          </li>
          <li className={style.breadcrumb}>
            <NewQueueButton pathname={pathname} />
          </li>
          {pathname.includes('/info-support/knowledge-base') && !pathname.includes('new-topic') && (
            <ACL allowed={PermissionsTable.info_support.modify} type="role">
              <li className={style.breadcrumb}>
                <button
                  type="button"
                  className="btn btn-primary btn-with-addon text-nowrap pull-right"
                  onClick={() => history.push('/info-support/knowledge-base/new-topic')}
                >
                  <span className="btn-addon">
                    <i className="btn-addon-icon fe fe-plus-circle" />
                  </span>
                  {getIntl('knowledgeBase.addNewTopicButtonText')}
                </button>
              </li>
            </ACL>
          )}
        </ul>
      </div>

      <div className="mr-auto d-md-block">{/* <IssuesHistory /> */}</div>

      <div className="mb-0 mr-4 d-xl-block d-none">{/* */}</div>

      {user.logged_as && (
        <div className="mr-4 d-sm-block">
          {filterRoutesByDisabledURLs({ disabledUrls: [] }) && <ResetLoginButton />}
        </div>
      )}
      <div className="mr-4 d-sm-block">
        {filterRoutesByDisabledURLs({ disabledUrls: [] }) && <UsageMenu />}
      </div>
      <div className="mr-4 d-sm-block">
        {filterRoutesByDisabledURLs({ disabledUrls: [] }) && <NotificationsMenu />}
      </div>
      <div className="">
        <UserMenu />
      </div>
    </div>
  )
}

export default connect(mapStateToProps)(TopBar)
