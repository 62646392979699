/* eslint-disable no-restricted-syntax */
import { message } from 'antd'
import { getIntl } from 'localization'

const checkScenarioParameterDuplicate = (scenario_parameters, item) => {
  for (const i in scenario_parameters) {
    if (scenario_parameters[i].name === item.name) {
      message.warning(
        getIntl('scenario_manager.scenario.task.name_must_differ_from_scenario') + item.name,
      )
      return true
    }
  }
  return false
}

const checkLocalDuplicate = (parameters, item) => {
  const duplicate_item = parameters.find(parameter => parameter.name === item.name)
  if (duplicate_item && duplicate_item.key !== item.key) {
    message.warning(`${getIntl('parameter_list.warning.name.duplicate')} : ${item.name}`)
    return true
  }
  return false
}

const emptyNameCheck = (parameters, item) => {
  if (item.name && item.name.trim() !== '') {
    return true
  }
  message.warning(getIntl('parameter_list.warning.name.required'))
  return false
}

export { checkScenarioParameterDuplicate, checkLocalDuplicate, emptyNameCheck }
