import { v4 } from 'uuid'

const getType = o => {
  if (Array.isArray(o)) {
    return 'array'
  }
  if (typeof o === 'number') {
    return o % 1 === 0 ? 'integer' : 'float'
  }
  if (typeof o === 'boolean') {
    return 'boolean'
  }
  return typeof o
}
const importer = params => {
  if(Array.isArray(params)) return params
  const p = []
  if (params) {
    Object.keys(params).forEach(key => {
      const o = {
        key: v4(),
        name: key,
        type: getType(params[key]),
        value: params[key],
      }
      p.push(o)
    })
  }
  return p
}

const exporter = params => {
  let o = null
  if (params && params.length > 0) {
    o = {}
    params.forEach(param => {
      Object.assign(o, { [param.name]: param.value })
    })
  }
  return o
}

export { importer, exporter }
