/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react'
import { Avatar, Modal, Card, Col, Row } from 'antd'
import { RobotFilled } from '@ant-design/icons'
import { getIntl } from 'localization'
import { FormattedHTMLMessage } from 'react-intl'
import style from './style.module.scss'

const RobotIcon = ({ src, setIcon, onlyPreview, size }) => {
  const [selectedIcon, setSelectedIcon] = useState(src)
  const [photoModalVisibility, setPhotoModalVisibility] = useState(false)

  const pickRobotPhoto = src => {
    setSelectedIcon(src)
    setIcon(src)
    setPhotoModalVisibility(false)
  }
  const onCancel = () => {
    setPhotoModalVisibility(false)
  }

  // Create fake array for robot image list
  const robotImages = [...Array(30)].map((e, i) => {
    return { id: i + 1, src: `/resources/images/avatars/robots/${i + 1}.png` }
  })

  return (
    <div>
      <Modal
        title={getIntl('robotManager.newRobot.robotIcon.label')}
        open={photoModalVisibility}
        onCancel={onCancel}
        width="60%"
        footer={null}
      >
        <Row gutter={[4, 4]}>
          {robotImages.map(image => (
            <Col sm={8} md={4} lg={4} key={image.id}>
              <Card hoverable onClick={() => pickRobotPhoto(image.src)}>
                <img alt="" src={image.src} width="100%" style={{ objectFit: 'contain' }} />
              </Card>
            </Col>
          ))}
          <Col span={24} className="mt-3">
            <small>
              <strong>
                <FormattedHTMLMessage id="Icons by" />
              </strong>
              <br />

              <a
                href="https://creativecommons.org/licenses/by/3.0/us/legalcode"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fa fa-creative-commons" /> Creative Commons
              </a>
            </small>
          </Col>
        </Row>
      </Modal>
      <Avatar
        shape="square"
        size={size || 96}
        src={selectedIcon}
        data-tour="robotIcon"
        icon={!selectedIcon && <RobotFilled />}
        className={onlyPreview ? style.avatarNoLink : style.avatarLink}
        onClick={!onlyPreview ? () => setPhotoModalVisibility(true) : undefined}
      />
    </div>
  )
}

export default RobotIcon
