import React from 'react'
import { Checkbox, Radio, Col, Input, Row, Button } from 'antd'
import { getIntl } from 'localization'
import { SearchOutlined } from '@ant-design/icons'
import { MAX_TABLE_FILTER_INPUT_LENGTH } from 'constantValues'

const tableFilteringHelper = (handleSearch, handleReset) => {
  const getColumnSearchProps = (dataIndex, placeholder, filters, multiple = false) => {
    const { Group } = multiple ? Checkbox : Radio
    return {
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Row gutter={[8, 8]} style={{ maxWidth: 200 }}>
            <Col span={24}>
              {filters ? (
                <Group
                  style={{ width: '50%' }}
                  onChange={e => {
                    setSelectedKeys(multiple ? e : `${e.target.value}`)
                  }}
                >
                  <Row gutter={[8, 8]}>
                    {filters.map(filter => (
                      <Col key={filter.value} span={24}>
                        {multiple ? (
                          <Checkbox value={filter.value}>{filter.label}</Checkbox>
                        ) : (
                          <Radio value={filter.value}>{filter.label}</Radio>
                        )}
                      </Col>
                    ))}
                  </Row>
                </Group>
              ) : (
                <Input
                  autoFocus
                  maxLength={MAX_TABLE_FILTER_INPUT_LENGTH}
                  placeholder={placeholder}
                  value={selectedKeys}
                  onChange={e => setSelectedKeys(e.target.value ? e.target.value : '')}
                  onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                  style={{ marginBottom: 8, display: 'block' }}
                />
              )}
            </Col>
            <Col span={24} className="text-center">
              <Button.Group>
                <Button
                  type="primary"
                  onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                  icon={<SearchOutlined />}
                  size="small"
                  style={{ width: 90 }}
                >
                  {getIntl('Search')}
                </Button>
                <Button
                  onClick={() => handleReset(clearFilters, dataIndex)}
                  size="small"
                  style={{ width: 90 }}
                >
                  {getIntl('Reset')}
                </Button>
              </Button.Group>
            </Col>
          </Row>
        </div>
      ),
      filterIcon: filtered => (
        <SearchOutlined
          style={{
            fontSize: filtered ? '1.2em' : undefined,
            color: filtered ? '#df255a' : undefined,
          }}
        />
      ),
    }
  }

  return getColumnSearchProps
}

export { tableFilteringHelper }
