import React, { useState, useEffect } from 'react'
import { Avatar, Button, Grid, Table, Tooltip, Divider } from 'antd'
import StatusTag from 'components/widgets/Tags/StatusTag'
import { history } from 'index'
import { getIntl } from 'localization'
import { cloneDeep } from 'lodash'
import { FormattedHTMLMessage } from 'react-intl'
import { connect } from 'react-redux'
import { staticOSList } from 'services/utilities'
import RobotActions from '../RobotActions'
import {
  InfoCircleFilled,
  AppleOutlined,
  QqOutlined,
  WindowsOutlined,
  ClearOutlined,
  RobotOutlined,
  NodeIndexOutlined,
} from '@ant-design/icons'
import { tableFilteringHelper } from 'utils/tableFilteringHelper'
import { getFirst, updateRecord } from 'api/requests'
import { getArtifactList } from 'services/agent'
import AgentArtifact from 'models/AgentArtifact'
import compareCalVersion from 'utils/compareCalVersion'
import RobotIcon from 'components/widgets/Photo/RobotIcon'

const initialFilters = {
  page: 1,
  pageSize: 10,
}

const OSIcon = ({ os }) => {
  const iconStyle = { fontSize: '1em' /*  padding: '30px 30px 0px 30px' */ }

  const OSList = {
    Windows: <WindowsOutlined style={iconStyle} />,
    Darwin: <AppleOutlined style={iconStyle} />,
    Linux: <QqOutlined style={iconStyle} />,
  }
  return OSList[os] || <></>
}

const mapStateToProps = ({
  robots: { robots, localRobots, readyRobots, count, loading },
  settings,
}) => ({ robotList: robots, localRobots, readyRobots, count, loading, settings })

const RobotList = ({
  dispatch,
  robotList,
  localRobots,
  readyRobots,
  count,
  loading,
  selectedRobots,
  setSelectedRobots,
  setReadyRobots = false,
  filterEnabled = true,
  robotManagerAvailable = false,
  robotProfileButton = true,
  inModal = false,
}) => {
  const { xs } = Grid.useBreakpoint()
  const [filters, setFilters] = useState(initialFilters)
  const [latestVersion, setLatestVersion] = useState()

  const getLatestVersion = async () => {
    const { success, data } = await getFirst('agentversions', { latest: true })
    if (success) {
      setLatestVersion(data.version)
    }
  }

  const getArtifactoryList = () => {
    const versionsObject = {}
    getArtifactList().then(({ success, data }) => {
      if (success) {
        const artifactList = data.results
          .map(file => new AgentArtifact(file))
          .filter(artifact => !artifact.name.includes('Launcher')) // Filter launcher artifact workaround
        artifactList.forEach(artifact => {
          Object.assign(versionsObject, { [artifact.system]: artifact.version })
        })
      }
    })
  }

  const localRobotCheck = () => {
    dispatch({
      type: 'robots/GET_LOCAL_ROBOTS',
      payload: { selectedRobots, setReadyRobots },
    })
  }

  /**
   * Gets robot object and compares the versions with the version list which retrived from File API.
   *
   * If there is a difference between robots and artifactory version then returns true
   * @param {Object} robot Robot object
   */
  const checkUpdate = robot => {
    if (!robot.version) return false
    let updateNeeded = false
    const robotVersion = robot?.version
    if (!latestVersion || !robotVersion) {
      updateNeeded = false
    } else {
      const comparison = compareCalVersion(robotVersion, latestVersion)
      updateNeeded = comparison === -1
    }
    return updateNeeded
  }

  const getRobots = filters => {
    dispatch({
      type: 'robots/GET_ROBOTS',
      payload: { filters },
    })
  }

  const handleSearch = (selectedKeys, confirm, filterName) => {
    if (confirm) confirm()
    const newFilters = { ...filters, ...initialFilters, [filterName]: selectedKeys || undefined }
    setFilters(newFilters)
    getRobots(newFilters)
  }

  const handleReset = (clearFilters, filterName) => {
    if (clearFilters) clearFilters()
    if (filterName) {
      setFilters({ ...filters, [filterName]: undefined })
      getRobots({ ...filters, [filterName]: undefined })
    } else {
      setFilters(initialFilters)
      getRobots()
    }
  }
  const handleRecoverRobot = async id => {
    const { success } = await updateRecord('robots', id, { robotconfig: { status: 'DOWN' } })
    if (success) getRobots(filters)
  }

  useEffect(() => {
    getLatestVersion()
    getRobots()
    getArtifactoryList()
  }, [])

  useEffect(() => {
    localRobotCheck()
  }, [robotList])

  useEffect(() => {
    if (robotManagerAvailable) localRobotCheck()
  }, [robotManagerAvailable])

  const columns = [
    {
      title: '',
      dataIndex: 'icon',
      key: 'icon',
      className: 'text-gray-6',
      responsive: ['md'],
      render: icon => <RobotIcon src={icon} onlyPreview size={32} />,
    },
    {
      title: getIntl('Robot Name'),
      dataIndex: 'name',
      key: 'name',
      className: 'text-gray-6',
      render: (text, rowData) => (
        <span className="font-weight-bold">
          {text}
          <br />
          {xs && <StatusTag fontSize={10} status={rowData.status} />}
        </span>
      ),
      ...tableFilteringHelper(handleSearch, handleReset)('name'),
      filteredValue: filters.name || null,
    },
    {
      title: getIntl('Operating System'),
      dataIndex: 'os',
      key: 'os',
      responsive: ['md'],

      className: 'text-gray-6',
      render: osKey => (
        <>
          <OSIcon os={osKey} />
          <span className="ml-2 font-weight-bold">
            {staticOSList.find(os => os.key === osKey)?.name || osKey}
          </span>
        </>
      ),
    },
    {
      title: getIntl('Version'),
      dataIndex: 'version',
      key: 'version',
      responsive: ['md'],

      className: 'text-gray-6',
      render: (text, data) => (
        <>
          <span className="font-weight-bold">{text}</span>
          {checkUpdate(data) && (
            <Tooltip
              title={
                <FormattedHTMLMessage
                  id="tooltip.robotManager.updateNeeded"
                  values={{ version: latestVersion }}
                />
              }
              className="ml-2"
            >
              <InfoCircleFilled />
            </Tooltip>
          )}
        </>
      ),
    },

    {
      title: getIntl('Status'),
      dataIndex: 'status',
      key: 'status',
      responsive: ['md'],
      className: 'text-center',
      render: (id, rowData, index) => (
        <>
          {!xs && <StatusTag status={rowData.status} />}
          {rowData.status === 'Lost' && (
            <Tooltip title={getIntl('tooltip.recoverLostRobot')}>
              <Button
                size="small"
                type="primary"
                icon={<NodeIndexOutlined />}
                onClick={() => handleRecoverRobot(rowData.id)}
              />
            </Tooltip>
          )}
        </>
      ),
      ...tableFilteringHelper(handleSearch, handleReset)('status', null, [
        { label: getIntl('Up'), value: 'UP' },
        { label: getIntl('Down'), value: 'DOWN' },
        { label: getIntl('Lost'), value: 'LOST' },
      ]),
      filteredValue: filters.status || null,
    },
    {
      title: (
        <Button
          onClick={() => handleReset()}
          size="small"
          disabled={filters === initialFilters}
          icon={<ClearOutlined />}
        >
          {!xs && getIntl('Clear Filters')}
        </Button>
      ),
      dataIndex: 'id',
      key: 'id',
      className: 'text-right',
      render: (id, rowData, index) => (
        <>
          {robotManagerAvailable && (
            <>
              <RobotActions
                small={xs}
                data={rowData}
                localRobots={localRobots}
                reload={() => getRobots(filters)}
              />
              <Divider type="vertical" />
            </>
          )}
          {robotProfileButton && !inModal && (
            <Tooltip title={getIntl('Robot Profile')}>
              <Button
                icon={<RobotOutlined />}
                type="primary"
                onClick={() => navigateToRobotDetail(id)}
              />
            </Tooltip>
          )}
        </>
      ),
    },
  ]

  const handleSelectedRobot = (robots, selected) => {
    const srobots = cloneDeep(selectedRobots) || []
    if (selected) {
      robots.forEach(robot => srobots.push({ id: robot.id, name: robot.name }))
      setSelectedRobots(srobots)
    } else {
      robots.forEach(r => {
        const index = srobots.findIndex(robot => robot.id === r.id)
        srobots.splice(index, 1)
      })
      setSelectedRobots(srobots)
    }
  }

  const rowSelection = {
    selectedRowKeys: selectedRobots.map(({ id }) => id),
    onSelect: (robot, selected) => {
      handleSelectedRobot([robot], selected)
    },
    onSelectAll: (selected, selectedRows, changeRows) => {
      handleSelectedRobot(changeRows, selected)
    },
  }

  const pagination = {
    total: count,
    current: filters.page,
    showSizeChanger: false,
    onChange: page => {
      if (filters.page === page) return false
      setFilters({ ...filters, page })
      getRobots({ page })
    },
  }

  const navigateToRobotDetail = id => {
    history.push(`/robot-manager/robots/${id}`)
  }

  return (
    <Table
      size="small"
      loading={loading}
      columns={columns}
      rowSelection={setSelectedRobots ? rowSelection : false}
      dataSource={robotList || []}
      pagination={pagination}
    />
  )
}
export default connect(mapStateToProps)(RobotList)
