import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { App, Col, Modal, Row, Table, Tag } from 'antd'
import { getIntl } from 'localization'
import axios from 'axios'
import Moment from 'moment'
import { API_URL, history } from 'index'
import { checkIfScenarioVersionInMarket } from 'services/utilities'
import OperationButtons from './components/OperationButtons'
import { updateRecentlyOpenedScenario } from 'utils/recentScenarios'

const ScenarioVersionsDialog = ({
  active_scenario,
  scenarios: { loading_version },
  modalOpen,
  setModalOpen,
  dispatch,
}) => {
  const { id: activeScenarioID, version_id, default_version_id } = active_scenario
  const [scenarioVersionsList, setScenarioVersionsList] = useState([])

  const { modal } = App.useApp()

  const getScenarioVersionList = async ({ paginationUrl }) => {
    dispatch({
      type: 'scenarios/SET_STATE',
      payload: { loading_version: true },
    })
    let url = null

    return axios(
      paginationUrl
        ? `${paginationUrl}&scenario=${activeScenarioID}`
        : `${API_URL}/scenarioversions/?scenario=${activeScenarioID}&order_by=-created`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('access')}`,
        },
      },
    )
      .then(result => {
        const { next, previous } = result.data
        url = next || previous
        return result.data
      })
      .then(r => {
        if (r.count && r.count > 0) {
          setScenarioVersionsList({
            ...r,
            paginationUrl: url ? `${url.split('page=')[0]}page=` : null,
          })
        }
      })
      .catch(e => console.log(e))
      .finally(() =>
        dispatch({
          type: 'scenarios/SET_STATE',
          payload: { loading_version: false },
        }),
      )
  }

  const changeVersion = async id => {
    const { exist } = await checkIfScenarioVersionInMarket(id)
    if (exist) {
      modal.error({
        title: getIntl('warnings.scenarioversionInMarket.defaultDisabled'),
      })
    } else {
      dispatch({
        type: 'scenarios/SET_STATE',
        payload: { loading_version: true },
      })
      return axios(`${API_URL}/scenarioversions/${id}/`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('access')}`,
        },
        data: { is_default: true },
      })
        .then(() => {
          history.replace(`/scenario-designer/?version=${id}`)
          updateRecentlyOpenedScenario(active_scenario.id, { version_id: id })
        })
        .catch(e => {
          return false
        })
    }
  }

  const deleteVersion = async id => {
    const { exist } = await checkIfScenarioVersionInMarket(id)
    if (exist) {
      modal.error({
        title: getIntl('warnings.scenarioversionInMarket.deleteDisabled'),
      })
    } else {
      modal.confirm({
        content: getIntl('confirmation.general.delete'),
        onOk: async () => {
          await axios(`${API_URL}/scenarioversions/${id}/`, {
            method: 'DELETE',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${localStorage.getItem('access')}`,
            },
          })
            .then(() => {
              getScenarioVersionList({})
              return true
            })
            .catch(e => {
              return false
            })
        },
      })
    }
  }

  const showVersion = id => {
    history.replace(`/scenario-designer/?scene=scenario&version=${id}&tab=design`)
    updateRecentlyOpenedScenario(active_scenario.id, { version_id: id })
    dispatch({
      type: 'scenario_editor/CHANGE_TAB',
      payload: { active_tab: 'scenario_tasks' },
    })
  }
  useEffect(() => {
    getScenarioVersionList({})
  }, [active_scenario.id])

  const columns = [
    {
      title: '#',
      dataIndex: 'id',
      key: 'id',
      className: 'text-gray-6',
    },
    {
      title: getIntl('Created At'),
      dataIndex: 'created',
      key: 'created',
      className: 'text-gray-6',
      render: datetime => Moment(datetime).format('DD.MM.YYYY HH:mm:ss'),
    },
    {
      title: getIntl('Description'),
      dataIndex: 'description',
      key: 'description',
      className: 'text-gray-6',
    },
    {
      dataIndex: 'id',
      key: 'statusInfo',
      className: 'text-gray-6',
      render: id => (
        <>
          {default_version_id === id && (
            <Tag color="green">
              <i className="fa fa-check mr-2" />
              {getIntl('scenarioVersionList.defaultVersion')}
            </Tag>
          )}
          {version_id === id && (
            <Tag color="orange">
              <i className="fa fa-warning mr-2" />
              {getIntl('scenarioVersionList.workingVersion')}
            </Tag>
          )}
        </>
      ),
    },
    {
      title: getIntl(''),
      dataIndex: 'id',
      key: 'manage',
      className: 'text-gray-6',
      align: 'right',
      render: id => (
        <OperationButtons
          id={id}
          activeVersion={version_id}
          defaultVersion={default_version_id}
          onVersionShow={() => showVersion(id)}
          onVersionChange={() => changeVersion(id)}
          onVersionDelete={() => deleteVersion(id)}
        />
      ),
    },
  ]

  const [activePage, setActivePage] = useState(1)
  const { paginationUrl, count } = scenarioVersionsList
  const pagination = {
    total: count,
    current: activePage,
    pageSize: 10,
    onChange: page => {
      setActivePage(page)
      getScenarioVersionList({ paginationUrl: `${paginationUrl}${page}` })
    },
  }

  return (
    <Modal
      width="100%"
      title={getIntl('Scenario Versions')}
      open={modalOpen}
      onCancel={() => setModalOpen(false)}
      footer={null}
      destroyOnClose
    >
      <Table
        loading={loading_version}
        dataSource={scenarioVersionsList.results}
        columns={columns}
        pagination={pagination}
      />
    </Modal>
  )
}

const mapStateToProps = ({ active_scenario, scenarios, settings }, ownProps) => ({
  active_scenario,
  scenarios,
  settings,
  ...ownProps,
})

export default connect(mapStateToProps)(ScenarioVersionsDialog)
