import {
  FieldTimeOutlined,
  ProjectOutlined,
  SettingOutlined,
  TeamOutlined,
} from '@ant-design/icons'
import React, { useEffect, useState } from 'react'
import { FormattedHTMLMessage } from 'react-intl'
import { connect } from 'react-redux'
import { Button, Modal, Card, Checkbox, Col, Form, Input, Layout, Row, Select, Tooltip } from 'antd'
import { getIntl } from 'localization'
import { updateRecentlyOpenedScenario } from 'utils/recentScenarios'
import { MAX_SCENARIO_NAME_LENGTH } from 'constantValues'
import { ActualEffortSuffix, FteExtra } from './components'
import { Spinner } from 'reactstrap'
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'

const mapStateToProps = ({ active_scenario, settings, scenarios, teams }) => ({
  active_scenario,
  settings,
  scenarios,
  teams,
})
const ScenarioSettingsDialog = ({
  active_scenario,
  settings,
  scenarios: { saving },
  teams: { loading, teams },
  dispatch,
}) => {
  const [modalOpen, setModalOpen] = useState(false)
  const [form] = Form.useForm()
  const { xs } = useBreakpoint()

  const saveSettings = () => {
    return new Promise((resolve, reject) => {
      form
        .validateFields()
        .then(values => {
          const {
            name,
            allowed_teams,
            is_public,
            fte,
            sync_stepruns,
            is_production,
            log_api_enabled,
          } = values

          dispatch({
            type: 'active_scenario/SET_STATE',
            payload: {
              ...active_scenario,
              name,
              allowed_teams,
              is_public,
              fte: fte || 3,
              sync_stepruns,
              is_production,
              log_api_enabled,
            },
          })
          updateRecentlyOpenedScenario(active_scenario.id, { name })
        })
        .then(() => {
          dispatch({
            type: 'scenarios/SAVE_SCENARIO',
          })
        })
        .then(() => {
          setModalOpen(false)
          resolve(true)
        })
        .catch(() => {
          reject()
        })
    })
  }

  const onChangeAllowedTeams = teamss => {
    if (teamss.length > 0) {
      form.setFieldsValue({ is_public: true })
    }
  }

  useEffect(() => {
    dispatch({
      type: 'teams/GET_TEAMS',
    })
  }, [])

  useEffect(() => {
    form.resetFields()
    form.setFieldsValue({
      allowed_teams: active_scenario.allowed_teams,
      fte: active_scenario.fte,
      sync_stepruns: active_scenario.sync_stepruns,
      is_production: active_scenario.is_production,
      log_api_enabled: active_scenario.log_api_enabled,
    })
  }, [active_scenario.id])

  const handleLogApiEnabled = value => {
    dispatch({
      type: 'settings/CHANGE_SETTING',
      payload: {
        setting: 'logApiEnabled',
        value,
      },
    })
  }
  return (
    <>
      <Modal
        title={<FormattedHTMLMessage id="Settings" />}
        open={modalOpen}
        width={xs ? '100%' : '60%'}
        onCancel={() => setModalOpen(false)}
        onOk={saveSettings}
        okText={getIntl('Save')}
        forceRender
      >
        <Layout.Content>
          <Row gutter={[8, 8]}>
            <Col span={8}>
              <strong>{`${getIntl('Created By')} : `}</strong>
              {` ${active_scenario.created_by.first_name} ${active_scenario.created_by.last_name}`}
            </Col>
          </Row>

          <Form
            form={form}
            onSubmit={saveSettings}
            layout="vertical"
            onFinish={saveSettings}
            initialValues={{
              name: active_scenario.name,
              is_public: active_scenario.is_public,
              allowed_teams: active_scenario.allowed_teams,
              fte: active_scenario.fte,
              is_production: active_scenario.is_production,
              log_api_enabled: active_scenario.log_api_enabled,
            }}
          >
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <Card>
                  <Form.Item label={getIntl('Scenario Name')} name="name">
                    <Input
                      maxLength={MAX_SCENARIO_NAME_LENGTH}
                      prefix={<ProjectOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                      placeholder={getIntl('Scenario Name')}
                    />
                  </Form.Item>
                  <Form.Item
                    label={getIntl('scenarioDesigner.settings.actualEffort.inputLabel')}
                    name="fte"
                    extra={<FteExtra />}
                  >
                    <Input
                      min={0}
                      type="number"
                      prefix={<FieldTimeOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                      placeholder={getIntl('scenarioDesigner.settings.actualEffort.inputLabel')}
                      suffix={<ActualEffortSuffix />}
                    />
                  </Form.Item>
                  <Form.Item name="is_public" labelAlign="right" valuePropName="checked">
                    <Checkbox>{getIntl('Is Public')}</Checkbox>
                  </Form.Item>
                  <Form.Item
                    label={
                      <>
                        <TeamOutlined style={{ color: 'rgba(0,0,0,.50)', marginRight: 5 }} />
                        {getIntl('Shared Teams')}
                      </>
                    }
                    name="allowed_teams"
                    rules={[{ type: 'array' }]}
                  >
                    {loading ? (
                      <Spinner />
                    ) : (
                      <Select loading={loading} mode="multiple" onChange={onChangeAllowedTeams}>
                        {teams.map(option => (
                          <Select.Option key={option.id} value={option.id}>
                            {option.name}
                          </Select.Option>
                        ))}
                      </Select>
                    )}
                  </Form.Item>
                </Card>
              </Col>
              <Col span={12}>
                <Card>
                  <Form.Item name="is_production" valuePropName="checked">
                    <Checkbox>Production</Checkbox>
                  </Form.Item>
                  <Checkbox
                    checked={settings.logApiEnabled}
                    defaultChecked={settings.logApiEnabled}
                    onChange={e => handleLogApiEnabled(e.target.checked)}
                  >
                    {getIntl('Save Logs On Platform')}
                  </Checkbox>
                </Card>
              </Col>
            </Row>
          </Form>
        </Layout.Content>
      </Modal>
      <Tooltip title={getIntl('Settings')}>
        <Button
          data-tour="settingsButton"
          loading={saving}
          icon={<SettingOutlined />}
          onClick={() => {
            setModalOpen(true)
          }}
        />
      </Tooltip>
    </>
  )
}

export default connect(mapStateToProps)(ScenarioSettingsDialog)
