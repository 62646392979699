import { versionFormatter } from 'services/utilities'

const { API_URL } = require('index')

class AgentArtifact {
  constructor({ key, os, os_name, arch, file, agent_version, launcher }) {
    this.key = key
    this.name = os_name
    this.file_name = file
    this.architecture = arch
    this.system = os.toLowerCase()
    this.version = agent_version.version || ''
    this.link = `${API_URL}/files/_shared/artifacts/${this.file_name}?raw=true`
    this.launcher = launcher
  }
}
export default AgentArtifact
