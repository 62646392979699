import React, { useState } from 'react'
import { Button, Modal, Tooltip } from 'antd'
import { getIntl } from 'localization'
import { ControlOutlined } from '@ant-design/icons'
import Parameters from 'components/widgets/ScenarioDesigner/Parameters/Parameters'
import { connect } from 'react-redux'
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'

const ParametersDialog = ({ scene }) => {
  const [modalOpen, setModalOpen] = useState(false)
  const { xs } = useBreakpoint()
  return (
    <>
      <Modal
        width={xs ? '100%' : '60%'}
        title={getIntl('Parameters')}
        open={modalOpen}
        onCancel={() => setModalOpen(false)}
        footer={null}
      >
        <div style={{ height: '50vh', overflowY: 'scroll', overflowX: 'hidden' }}>
          <Parameters container={scene} />
        </div>
      </Modal>
      <Tooltip title={getIntl('Parameters')}>
        <Button
          data-tour="parametersButton"
          icon={<ControlOutlined />}
          onClick={() => {
            setModalOpen(true)
          }}
        />
      </Tooltip>
    </>
  )
}

const mapStateToProps = ({ scenario_editor }) => ({ scene: scenario_editor.scene })
export default connect(mapStateToProps)(ParametersDialog)
