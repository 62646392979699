class ActiveScenario {
  constructor(scenario, scenario_version) {
    this.id = scenario.id
    this.name = scenario.name
    this.description = scenario.description
    this.is_public = scenario.is_public
    this.allowed_teams = scenario.allowed_teams.map(team => team.id)
    this.created_by = scenario.created_by
    this.version_id = scenario_version.id
    this.default_version_id = scenario.default_scenarioversion?.id
    this.fte = scenario_version.fte
    this.flow_data = scenario_version.flow_data
    this.content = scenario_version.content
    this.robots = scenario_version.robots
    this.sync_stepruns = scenario_version.sync_stepruns
    this.is_production = scenario.is_production
    this.log_api_enabled = scenario.log_api_enabled
    this.updated = scenario.updated

    this.created_by = {
      first_name: scenario.created_by?.first_name,
      last_name: scenario.created_by?.last_name,
    }
  }
}

export default ActiveScenario
