import React, { useEffect, useState } from 'react'
import { App, Avatar, Button, Card, Col, Divider, Form, Input, Row, Typography } from 'antd'
import { CheckCircleTwoTone } from '@ant-design/icons'
import { getIntl } from 'localization'
import RobotIcon from 'components/widgets/Photo/RobotIcon'
import { API_URL, history } from 'index'
import axios from 'utils/axiosGlobal'
import { setBreadCrumbs } from 'utils/breadcrumber'
import { getList, headers } from 'api/requests'
import { MAX_ROBOT_NAME_LENGTH } from 'constantValues'

const { Text } = Typography

const NewRobot = ({
  fullWidth = false,
  inModal = false,
  onSuccess = () => console.log('Success'),
}) => {
  const [form] = Form.useForm()
  const { modal } = App.useApp()

  const [selectedIcon, setSelectedIcon] = useState(null)
  const [robotNameValid, setRobotNameValid] = useState(null)
  const [robotChecking, setRobotChecking] = useState(false)

  useEffect(() => {
    setBreadCrumbs([
      { title: 'Robots', link: '/robot-manager/robots' },
      { title: 'robotManager.newRobot.pageTitle', link: '/robot-manager/new-robot' },
    ])
  }, [])

  const callToAPI = async ({ payload }) => {
    const robot_data = {
      name: payload.name,
      robotconfig: {
        icon: payload.icon,
      },
    }

    const url = `${API_URL}/robots/`

    return axios(url, {
      method: 'POST',
      headers: headers(),
      data: robot_data,
    })
      .then(result => {
        const robot = result.data
        return {
          success: true,
          robot,
        }
      })
      .catch(error => {
        return { success: false }
      })
  }

  const DocumentationInfo = () => {
    const robotDocumentationInfo = getIntl('robotManager.newRobot.successDialog.documentationInfo')
    return (
      <Typography.Text>
        <small>
          {robotDocumentationInfo[0]} <a href="">{robotDocumentationInfo[1]}</a>{' '}
          {robotDocumentationInfo[2]}
        </small>
      </Typography.Text>
    )
  }

  const createNewRobot = () => {
    form
      .validateFields()
      .then(values => {
        values = { ...values, icon: selectedIcon }
        modal.confirm({
          title: getIntl('robotManager.newRobot.createConfirmDialog.title'),
          content: (
            <Row gutter={[16, 16]} className="text-center mt-2">
              <Col span={24}>
                <RobotIcon src={selectedIcon} onlyPreview />
              </Col>
              <Col span={24}>
                <h5>{values.name}</h5>
              </Col>
            </Row>
          ),
          onOk: async () => {
            const { success, robot } = await callToAPI({ payload: values })
            if (success) {
              if (inModal) {
                onSuccess()
                return
              }
              modal.confirm({
                icon: <CheckCircleTwoTone twoToneColor="#52c41a" />,
                width: 600,
                title: getIntl('robotManager.newRobot.create.success.title'),
                content: (
                  <div>
                    <Text strong>
                      {getIntl('robotManager.newRobot.create.success.description')}
                    </Text>
                    <br />
                    <Text code copyable>
                      {robot.robotconfig.key}
                    </Text>
                    <br />
                    <DocumentationInfo />
                  </div>
                ),
                onOk: () => {
                  history.push(`/robot-manager/robots/${robot.id}`)
                },
                onCancel: () => {
                  form.resetFields()
                  onSuccess()
                },
                okText: getIntl('robotManager.newRobot.successDialog.okButton'),
                cancelText: getIntl('robotManager.newRobot.successDialog.cancelButton'),
              })
            } else {
              modal.error({
                width: 600,
                title: getIntl('robotManager.newRobot.create.error.title'),
                content: (
                  <div>
                    <Text strong>{getIntl('robotManager.newRobot.create.error.description')}</Text>
                    <br />
                    <Text code copyable>
                      {robot.robotconfig.key}
                    </Text>
                  </div>
                ),
              })
            }
          },
        })
      })
      .catch(err => {})
  }
  const checkNameExists = async name => {
    setRobotChecking(true)
    const { success, data } = await getList('robots', { name, iexact: true })
    setRobotChecking(false)

    if (success && data.count === 1) {
      setRobotNameValid(false)
      return true
    }
    setRobotNameValid(true)
    return false
  }
  return (
    <Form form={form} scrollToFirstError>
      <Row justify="center">
        <Col sm={fullWidth ? 24 : 12} xs={24} className="text-center">
          <h6 className="mb-4">
            <strong>{getIntl('robotManager.newRobot.robotIcon.label')}</strong>
          </h6>
          <RobotIcon src={selectedIcon} setIcon={setSelectedIcon} />
        </Col>
      </Row>
      <Divider />
      <Row justify="center">
        <Col sm={fullWidth ? 24 : 12} xs={24} className="text-center">
          <h6 className="mb-4">
            <strong>{getIntl('robotManager.newRobot.robotName.label')}</strong>
          </h6>
          <Form.Item
            name="name"
            rules={[
              {
                required: true,
                message: getIntl('formValidations.messages.robot_name.required'),
              },
              () => ({
                async validator(_, value) {
                  const exists = await checkNameExists(value)
                  if (exists) {
                    return Promise.reject(new Error(getIntl('robotManager.newRobot.nameInUse')))
                  }
                  return Promise.resolve()
                },
              }),
            ]}
          >
            <Input
              maxLength={MAX_ROBOT_NAME_LENGTH}
              placeholder={getIntl('robotManager.newRobot.robotName.placeholder')}
              autoFocus
              size="large"
              style={{ borderRadius: 5 }}
            />
          </Form.Item>
        </Col>
      </Row>
      <Divider />
      <Row>
        <Col span={24} className="text-center">
          <Button
            disabled={!robotNameValid || robotChecking}
            type="primary"
            size="large"
            onClick={createNewRobot}
            data-tour="createRobotFinalButton"
          >
            {getIntl('robotManager.newRobot.createButton')}
          </Button>
        </Col>
      </Row>
    </Form>
  )
}

export default NewRobot
