import { LockOutlined, SaveOutlined, StopOutlined } from '@ant-design/icons'
import { Button, Col, Divider, Form, Input, Row } from 'antd'
import { getIntl } from 'localization'
import React, { useState, useEffect } from 'react'
import KeyValueList from './KeyValueList'

const VaultForm = ({ vault, onCreate, onUpdate, onCancel, onLock, predefinedValues }) => {
  const [form] = Form.useForm()
  const [data, setData] = useState([])
  const [saveDisabled, setSaveDisabled] = useState(false)

  useEffect(() => {
    setSaveDisabled(data.filter(d => d.initialSave === true).length > 0)
  }, [data])

  useEffect(() => {
    if (vault?.id) {
      const { name, description, decrypted } = vault
      form.setFieldsValue({
        name,
        description,
      })
      setData(JSON.parse(decrypted).map(d => ({ key_name: d.key, ...d })))
    }

    if (predefinedValues) {
      setData(predefinedValues)
    }
  }, [])

  const handleCancel = () => {
    form.resetFields()
    if (onLock) onLock()
    if (onCancel) onCancel()
    setData([])
  }

  const handleSaveVault = () => {
    if (saveDisabled) {
      return false
    }
    form
      .validateFields()
      .then(values => {
        const { name, description } = values
        const unencryptedPayload = JSON.stringify(
          data.map(({ key_name, value }) => ({ key: key_name, value })),
        )
        onCreate({ name, description, unencryptedPayload })
        form.resetFields()
        setData([])
      })
      .catch(e => console.log(e))
    return true
  }

  const handleUpdateVault = () => {
    if (saveDisabled) {
      return false
    }
    form
      .validateFields()
      .then(values => {
        const { description } = values
        const unencryptedPayload = JSON.stringify(
          data.map(({ key_name, value }) => ({ key: key_name, value })),
        )
        onUpdate({ id: vault.id, description, unencryptedPayload })
      })
      .catch(e => console.log(e))

    return true
  }

  return (
    <Row>
      <Col span={24}>
        <Form form={form} onFinish={onUpdate ? handleUpdateVault : handleSaveVault}>
          <Row>
            <Col span={24}>
              <Form.Item
                disabled={!!onUpdate}
                name="name"
                label={getIntl('Vault Name')}
                rules={[
                  {
                    required: true,
                    message: getIntl('formValidations.vaultAdd.required.name'),
                  },
                ]}
              >
                <Input disabled={!!onUpdate} />
              </Form.Item>
              <Form.Item
                name="description"
                label={getIntl('Vault Description')}
                rules={[
                  {
                    required: true,
                    message: getIntl('formValidations.vaultAdd.required.description'),
                  },
                ]}
              >
                <Input.TextArea />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <KeyValueList data={data} onChange={setData} />
          </Row>
          <Divider />
          <Row>
            <Col span={24}>
              <div className="btn-group float-right">
                <Button
                  icon={onUpdate ? <LockOutlined /> : <StopOutlined />}
                  onClick={handleCancel}
                >
                  {onUpdate ? getIntl('Lock') : getIntl('Cancel')}
                </Button>
                <Divider type="vertical" />
                <Button
                  htmlType="submit"
                  icon={<SaveOutlined />}
                  disabled={saveDisabled}
                  type="primary"
                >
                  {onUpdate ? getIntl('Update') : getIntl('Save')}
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      </Col>
    </Row>
  )
}

export default VaultForm
